import React from 'react';
import Colors from '../components/colors'

const SvgComponent = props => (
  <svg viewBox="0 0 51.1 63.02" {...props}>
    <defs>
      <style>{`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-3{fill:${Colors.Accent2}}`}</style>
    </defs>
    <title>{'Consult'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g
        id="prefix__Real_Estate_Homescapes_Icons"
        data-name="Real Estate &amp; Homescapes Icons"
      >
        <g id="prefix__Win_Win_Negotiation" data-name="Win Win Negotiation">
          <path
            className="prefix__cls-1"
            d="M29.35 1.21a6.47 6.47 0 11-6.51 6.51 6.46 6.46 0 016.51-6.51zM34.77 54.7h6.73c0 1.81.13 3.59 0 5.34a3.24 3.24 0 01-3.59 2.66 3.29 3.29 0 01-3.09-3c-.11-1.63-.05-3.27-.05-5z"
          />
          <circle className="prefix__cls-1" cx={7.82} cy={5.65} r={5.65} />
          <path
            className="prefix__cls-1"
            d="M33.93 38.49a2.44 2.44 0 002.21-.44c.92-.71.78-1.43.53-2.41-.21-.77-1.85-6.34-2.46-8.38-.33-1.09-1-2.12.55-2.61.28-.09.6.32.69.53a9.08 9.08 0 01.43 1.37l.63 2c.42 1.31.81 2.63 1.2 4 .28 1 .51 1.91.74 2.88a.72.72 0 00.71.55h4a.48.48 0 00.46-.6c-.21-.88-.47-2-.66-2.72-1.19-4.73-2.37-9.46-3.6-14.19a4.14 4.14 0 00-2.84-3 6.25 6.25 0 00-5.62 1.06c-1.84 1.42-2 3.08-1.86 5.27v.42a.16.16 0 01-.07.14c-4.17 2.79-7.06 1.47-7.26 1.38l-.24-.11a2.17 2.17 0 00-1.54-.41c-5 .48-7.78-.76-9.29-2v-3a5.45 5.45 0 00-4.77-5.51A5.31 5.31 0 000 17.91V36.2a5.32 5.32 0 002.89 4.72V60.6A2.41 2.41 0 005.3 63h5.91a2 2 0 100-4h-3.5V40.92a5.32 5.32 0 002.89-4.72v-9.83a17.58 17.58 0 007.15 1.36c.58 0 1.19 0 1.83-.07h.09c.63.31 4.63 2.09 9.94-.55a.15.15 0 01.22.1c.56 2.64 1.28 5.27 1.8 7.88a8.14 8.14 0 00.7 2 3.28 3.28 0 001.6 1.4z"
          />
          <path d="M38.53 36.83h.05" fill="none" />
          <path
            className="prefix__cls-3"
            d="M37.84 39.53a.44.44 0 00.44-.44v-.2a1.17 1.17 0 011.17-1.18h3.88a1.18 1.18 0 011.18 1.18v.2a.44.44 0 10.88 0v-.2a2.06 2.06 0 00-2.06-2.06h-3.88a2.06 2.06 0 00-2.06 2.06v.2a.44.44 0 00.45.44zM49 40.16H34.13A2.13 2.13 0 0032 42.29v8.3a2.13 2.13 0 002.13 2.13H49a2.13 2.13 0 002.13-2.13v-8.3A2.13 2.13 0 0049 40.16zm.81 9.55a1.76 1.76 0 01-1.84 1.68H35.15a1.76 1.76 0 01-1.83-1.68v-3.6a29.17 29.17 0 006.23 1v-.26a2 2 0 012-2 2 2 0 012 2v.26a29.17 29.17 0 006.23-1z"
          />
          <path
            className="prefix__cls-1"
            d="M41.55 45.66a1.18 1.18 0 00-1.18 1.18v.91a1.18 1.18 0 102.36 0v-.91a1.18 1.18 0 00-1.18-1.18z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
