import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Colors from './colors';
import Card from '@material-ui/core/Card';
import { CardContent, CardMedia, Button } from '@material-ui/core';

import HomeSearch from '../images/homesearch.jpg';
import FoundHome from '../images/foundhome.jpg';
import Learn from '../images/learning.jpg';
import { Link } from 'react-router-dom';

import { useStore } from '../components/useFormState'

const useStyles = makeStyles(theme => ({
    card: {

        borderRadius: '15px',
        boxShadow: '3px 7px 9px 2px rgba(0, 0, 0, 0.4), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',

        margin: '1rem 1rem 2rem',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '31rem'
    },
    header: {
        color: Colors.Accent2,
        margin: '2rem 1rem 1rem',
        fontSize: '2rem',
        fontWeight: '900',
        [theme.breakpoints.down('sm')]: {
            margin: '8rem 1rem 1rem'
        }
    },
    subHeader: {
        color: Colors.Accent2,
        margin: '2rem 1rem 1rem',
        fontSize: '1.5rem',
        fontWeight: '500',
    },
    cardMedia: {
        height: '250px',
        margin: '-1rem'
    },
    cardButton: {
        color: Colors.white,
        margin: '1rem 0 0'
    }
}));

const cards = [
    [FoundHome, 'Couple Reviewing offer on home.', 'I have found a home and I\'m ready to make an offer.', `That's great to hear! You can apply for a mortgage at the button below. Don't wait that home may not avaiable for long`, 'Apply Now',],
    [HomeSearch, 'Woman looking for properties on line.', `I am actively searching for my next home.`, 'Fantastic! Now is the time to speak with a trusted lender to receive your pre-approval letter. Apply below to get pre-approved.', 'Get Pre-approved'],
    [Learn, 'Woman researching on her computer', 'I\'m not ready, still learning about the process.', `We would love to help you prepare for one of the largest investments you may ever make. Apply at the button below.`, `Lets talk`],
];


export default function Timeline() {
    const classes = useStyles();

    const { dispatch } = useStore();

    return (
        <Grid container direction='column' justify='center' align='center' >
            <Typography variant='h2' className={classes.header} gutterBottom>
                How can we help you?
            </Typography>
            <Grid container direction='row' justify='center'>
                {cards.map((card, index) => (
                    <Grid item className={classes.cardContainer} xs={10} sm={6} md={4} lg={3} xl={2} key={index}>

                        <Card className={classes.card}>
                            <CardContent classes={{ root: classes.root }}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={card[0]}
                                    title={card[1]}
                                />
                                <Typography variant='h4' className={classes.subHeader} gutterBottom>
                                    {card[2]}
                                </Typography>
                                <Typography variant="body2" color="textPrimary" align='left' component="h6" gutterBottom >
                                    {card[3]}
                                </Typography>
                                <Button
                                    color='secondary'
                                    variant='contained'
                                    fullWidth={true} size='large'
                                    component={Link} to='/apply'
                                    classes={{ containedSecondary: classes.cardButton }}
                                    onClick={(e) => { dispatch({ type: "handleChange", name: 'loanType', value: 'Purchase' }); dispatch({ type: "handleChange", name: 'status', value: card[2] }) }}
                                >
                                    {card[4]}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}