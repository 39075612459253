import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Colors from './colors';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';


//icon
import BuildIcon from '../images/construction';
import MonetizationOnIcon from '../images/hardmoney';
import StorefrontIcon from '../images/commercial';
import HomeRepair from '../images/homerepair';
import MobileHome from '../images/mobilehome';
import NonQm from '../images/nonqm';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '2rem 0 ',
        alignContent: 'center',
        background: Colors.owbg
    },
    header: {
        color: Colors.darkAccent1,
        fontSize: '2rem',
        fontWeight: '900',
    },
    cardContainer: {
        [theme.breakpoints.down('xl')]: {
            width: '70%'
        },
        [theme.breakpoints.down('lg')]: {
            width: '80%'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: '0 auto'
        },

    },
    cardWrapper: {
        padding: '1rem 1rem 2rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem 0 2rem'
        }
    },
    iconWrapper: {
        height: '100px',
        width: '100px',
        margin: '0 0 3rem',
        background: Colors.white,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `4px solid ${Colors.lightAccent1}`,
        boxShadow: '3px 7px 9px 2px rgba(0, 0, 0, 0.4), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    cardIcon: {
        height: '80px',
        width: '80px',
        padding: '1rem',

    },
    card: {
        padding: '1.5rem',
        minHeight: '30rem',
        borderRadius: '15px',
        background: Colors.Accent2,
        boxShadow: '3px 7px 9px 2px rgba(0, 0, 0, 0.4), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    tileText: {
        background: Colors.white,
        borderRadius: '10px',
    },
    tileHeader: {
        color: Colors.Accent2,
        padding: '1rem 0 0',
        margin: '1rem',
        background: Colors.white,
        fontWeight: '900',
        borderRadius: '15px',
        "&:after": {
            content: '""',
            display: 'block',
            width: '3rem',
            height: '2px',
            background: Colors.Accent2,
            margin: '.5em auto .65em',

        }
    },
    tileBlup: {
        color: Colors.dark,
        fontWeight: '400',
        background: Colors.white,
        padding: '1rem',
        borderRadius: '10px'
    }
}));



export default function OtherPrograms() {
    const classes = useStyles();
    const MediumOrBelow = useMediaQuery(theme => theme.breakpoints.down('md'));
    const programs = [
        ['Alternative Income Loans', <NonQm className={classes.cardIcon} />, 'Are you self-employed? Maybe you have good cash flow but you you have been turned down at another bank for a home loan because you don\'t show enough income on your tax returns. If this describes you, we can help! We can get you qualified for your next home loan using the cash flow from your bank statements. Rates are slightly higher, but for the self-employed with insufficient taxable income, this loan program is a great option.'],
        ['Manufactured Homes', <MobileHome className={classes.cardIcon} />, 'Manufactured homes are a great option for some families in Redding, CA. We can finance a manufactured home with a conventional, FHA, or VA loan program. Low down payment options exist for these homes just like a traditional home. '],
        ['Commercial Mortgages', <StorefrontIcon className={classes.cardIcon} />, 'For both investment properties and traditional commercial real estate, these mortgages enable the entrepreneurs in Redding, CA. We have loans available to finance multi-unit properties with 5 units or more.'],
        ['Hard Money', <MonetizationOnIcon className={classes.cardIcon} />, 'Special loan progam for investors in the Redding, CA, area: These loans can help you finance a fixer upper, or if you have sub-par credit, you can use a Hard Money loan to purchase properties. These loan programs typically require larger downpayments.'],
        ['Renovation Loans', <HomeRepair className={classes.cardIcon} />, 'If you are looking to buy a home in the Redding area that needs some fixing up, or if you would like to renovate the house you live in, we have loan programs that can help. That new kitchen you have been dreaming of can become a reality. Don\'t just like it on Pinterest, live in it!'],
        ['Construction Loans', <BuildIcon className={classes.cardIcon} />, 'Redding Home Loans is one of the few lenders in town that offer construction loans. If you have always wanted to build that dream house, our construction loan programs can help you complete your project from site work to shingles, and everything in between.'],
    ];
    return (
        <Grid
            container
            direction='column'
            justify='center'
            align='center'
            className={classes.root}
        >
            <Typography
                className={classes.header}
                variant='h2'
                gutterBottom
            >
                Non traditional programs
            </Typography>
            <Grid container direction='row' justify={!!MediumOrBelow ? 'center' : null} style={!!MediumOrBelow ? ({ maxHeight: '100vh', overflowX: 'scroll' }) : null} className={classes.cardContainer}>

                {programs.map((program, index) => (
                    <Grid item className={classes.cardWrapper} xs={10} sm={6} md={4} lg={4} xl={3} key={index}>
                        <Card
                            className={classes.card}
                        >
                            <CardContent
                                key={index}
                                component='div'
                                className={classes.tile}
                            >
                                <Grid
                                    item
                                    className={classes.iconWrapper}
                                >
                                    {program[1]}
                                </Grid>
                                <Grid
                                    item
                                    className={classes.tileText}
                                >
                                    <Typography
                                        variant="h6"
                                        className={classes.tileHeader}
                                    >
                                        {program[0]}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        align='left'
                                        className={classes.tileBlup}
                                    >
                                        {program[2]}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid >
    )
}