import React from 'react';
import { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Colors from './colors';
import HeroBG from '../images/ratesHeroBG.jpg';
import HeroBG2 from '../images/ratesHeroBG@2x.jpg';
import HeroBG3 from '../images/ratesHeroBG@3x.jpg';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: Colors.Accent2,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.default,
        },
        '&:nth-of-type(odd)': {
            backgroundColor: 'white',
        },
    },
}))(TableRow);


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 700,
        minHeight: 450,
        maxWidth: '70vw',


    },
    container: {
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${HeroBG})`,
            backgroundPosition: 'top center',
            backgroundSize: 'cover',

        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${HeroBG2})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',

        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${HeroBG3})`,
            backgroundPosition: 'center 0px center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',

        },
    },
    tableWrapper: {
        background: 'white',
        maxWidth: '90vw',
        margin: '1rem',
        overflowX: 'scroll',
        borderRadius: '15px',
        boxShadow: Colors.shadow,


    },
    subtext: {
        margin: '.5rem',
        fontSize: '.5rem'
    }
}));

export default function CustomizedTables() {
    const classes = useStyles();
    const [rows, setRows] = React.useState(null);
    const getReviews = async () => {
        const url = 'https://us-central1-reddinghomeloan.cloudfunctions.net/rates';
        const params = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'applications/json'
            }
        }
        const response = await fetch(url, params);
        const res = await response.json();
        return JSON.parse(res.json);
    }

    useEffect(() => {
        if (rows === null) {
            getReviews().then(
                (r) => {
                    if (r) {
                        setRows(r);
                    }
                }
            );
        }

    });

    if (rows === null) { return (<div id="text"></div>); }

    return (
        <Grid container justify='center' alignItems='center' direction='column' className={classes.container}>

            <Typography variant='h4' style={{ margin: '3rem 1rem 0' }}>
                Our current rates
            </Typography>
            <Typography variant='body1' >
                Our rates are updated weekly.
            </Typography>

            <Grid item className={classes.tableWrapper}>

                <Table className={classes.table} aria-label="customized table">
                    <TableHead>

                        <TableRow>
                            <StyledTableCell>Mortgage Program</StyledTableCell>
                            <StyledTableCell align="center">Rate</StyledTableCell>
                            <StyledTableCell align="center">Lender Fees</StyledTableCell>
                            <StyledTableCell align="center">Down Payment</StyledTableCell>
                            <StyledTableCell align="center">APR</StyledTableCell>
                            <StyledTableCell align="center">Loan Term</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <StyledTableRow key={row.program}>
                                <StyledTableCell component="th" scope="row">
                                    {row.program}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.rate}</StyledTableCell>
                                <StyledTableCell align="right">{row.lenderFees}</StyledTableCell>
                                <StyledTableCell align="right">{row.downPayment}</StyledTableCell>
                                <StyledTableCell align="right">{row.apr}</StyledTableCell>
                                <StyledTableCell align="right">{row.loanTerm}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <Grid container align='left'>
                    <Grid item>
                        <Typography variant='subtitle1' className={classes.subtext}>
                            * Government Loans with up front funding fee. Call for details
                        </Typography>
                        <Typography variant='subtitle1' className={classes.subtext}>
                            ! Loans have monthly mortgage Insurance
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}
