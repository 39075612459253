import React from 'react';
import Grid from '@material-ui/core/Grid';
import Form from '../components/useForm';
import bg from '../images/heroBG.jpg';
import bg2 from '../images/heroBG@2x.jpg';
import bg3 from '../images/heroBG@3x.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../components/colors';

const useStyles = makeStyles(theme => ({
    background: { background: Colors.owbg },
    hero: {
        height: '100vh',
        width: '100%',
        position: 'relative',/*
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',*/
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'top center',
            backgroundSize: 'cover',
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${bg2})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${bg3})`,
            backgroundPosition: 'center 0px center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
    },

}));

export default function Apply() {
    const classes = useStyles();

    return (
        <Grid
            container
            wrap='wrap'
            direction='column'
            justify='flex-start'
            align='center'
            className={classes.hero}
        >
            <Form />
        </Grid >

    )
}