import React from 'react'
import Colors from '../components/colors'

const SvgComponent = props => (
    <svg viewBox="0 0 132.22 112.67" {...props}>
        <defs>
            <style>
                {`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}
            </style>
        </defs>
        <title>{'Asset 6'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g id="prefix__Layer_1-2" data-name="Layer 1">
                <path
                    className="prefix__cls-1"
                    d="M75.63 64.51l21.59-3.45 21.54-3.84a6.16 6.16 0 017 5.13l3.92 25a6.36 6.36 0 01-5 7.15l-2.91.47a7.19 7.19 0 00-1.09-3.86l3.38-.57a2 2 0 001.76-2.54l-1.77-12.51L121.91 63a1.92 1.92 0 00-2.53-1.82l-21.56 3.65-21 3.54a6 6 0 00-3.18-.85 6.24 6.24 0 00-2.53.51 6.12 6.12 0 014.52-3.52z"
                />
                <path
                    className="prefix__cls-1"
                    d="M67.48 71v25.27a6.27 6.27 0 006.08 6.22l21.85-.2 21.85.2a6.27 6.27 0 006.08-6.22V71a6.27 6.27 0 00-6.08-6.22l-21.85.2-21.85-.2A6.27 6.27 0 0067.48 71zm3.87 25.3l-.2-12.65.2-12.65a1.94 1.94 0 012.21-2.22h43.7a1.94 1.94 0 012.21 2.22l.19 12.64-.19 12.65a1.93 1.93 0 01-2.21 2.21h-43.7a1.93 1.93 0 01-2.21-2.24z"
                />
                <path
                    className="prefix__cls-2"
                    d="M74.83 78.85v9.52c3.5 1 6.78 7.09 6.78 7.09h27.59s3.29-6.08 6.79-7.09v-9.52c-3.5-1-6.79-7.08-6.79-7.08H81.61s-3.28 6.07-6.78 7.08zM89 83.61a6.79 6.79 0 015.44-6.38 4.6 4.6 0 012 0 6.47 6.47 0 010 12.77 5.05 5.05 0 01-2 0A6.8 6.8 0 0189 83.61z"

                />
                <path
                    className="prefix__cls-1"
                    d="M127.14 78.74a2.05 2.05 0 00.3-2.27l-4-11.93L119 52.75a1.89 1.89 0 00-2.82-1.28l-20.49 7.87-18.13 7a6.45 6.45 0 00-7.56 2 6.27 6.27 0 013.86-4.88l20.56-7.69 20.42-8.06a6.09 6.09 0 017.79 3.65l8.45 23.73a6.58 6.58 0 01-2.08 7.12 6.49 6.49 0 00-1.86-3.47z"
                />
                <path
                    className="prefix__cls-1"
                    d="M114.44 41.9a3.56 3.56 0 01.17-.41 12.69 12.69 0 00-1.15-1Q102.23 31.87 91 23.24 77.19 12.62 63.33 2C60-.54 56.72-.64 53.8 1.61Q44.14 9 34.5 16.46c-.57.45-1.24.86-2.4 1.67v-6a2.59 2.59 0 00-2.6-2.59h-9.87A2.58 2.58 0 0017 12.1c0 5.86.06 10.84-.09 15.8a4.71 4.71 0 01-1.72 3.24c-4.2 3.45-8.67 6.67-12.94 10A5.35 5.35 0 00.9 48.5 6.92 6.92 0 008.41 51a11.23 11.23 0 003.5-1.91C26.65 37.83 41.37 26.61 56 15.28c1.89-1.48 3-1.4 4.78 0 8.32 6.57 16.75 12.96 25.14 19.42 4.23 3.26 8.46 6.55 12.71 9.79s15.69-2.14 15.81-2.59z"
                />
                <path
                    className="prefix__cls-1"
                    d="M90.24 46.12c-.95.73-4.49 6.84-4.49 6.84l-.28.15-.85-.62-24-17.49a4.19 4.19 0 00-4.89 0L19.38 61.35l-1 .78a4.13 4.13 0 00-1.74 3.37v40.36a5.29 5.29 0 01-.05.56h9.19c-.15-.11-.15-.41-.15-.88V79.21a11.79 11.79 0 018.77-11.72 11.26 11.26 0 0113.6 9 13.87 13.87 0 01.2 2.47v26.65c0 .43-.07.69-.28.84h39.27v7.25H4v-7.25h4.24a5.29 5.29 0 01-.05-.56V59.1l4-2.9 45.86-33.41.13.11.17-.11z"
                />
                <path
                    className="prefix__cls-2"
                    d="M60.52 45.33v16h9v-16zM46.88 45.33v16h9v-16z"
                />
                <path
                    className="prefix__cls-1"
                    d="M85.81 53.34c.2-.11 8.19-3.86 8.81-4l-6.36-4.64-5 6.81 2.53 1.84z"
                />
            </g>
        </g>
    </svg>
)

export default SvgComponent

