import React from 'react';
import Colors from '../components/colors'

const SvgComponent = props => (
  <svg viewBox="0 0 63.21 51.38" {...props}>
    <defs>
      <style>{`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}</style>
    </defs>
    <title>{'Apply'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g
        id="prefix__Real_Estate_Homescapes_Icons"
        data-name="Real Estate &amp; Homescapes Icons"
      >
        <g id="prefix__Apply">
          <path
            className="prefix__cls-1"
            d="M58.49 46a5.52 5.52 0 000-.71V16.55a4.63 4.63 0 00-4.63-4.63h-5.47v3.66h6.79V46H8V15.58h6.76v-3.66H9.3a4.64 4.64 0 00-4.64 4.63v28.76a5.52 5.52 0 000 .71H0v.73a4.63 4.63 0 004.63 4.63h53.94a4.64 4.64 0 004.64-4.63V46zm-23.15 3.24c0 .37-.19.67-.41.67h-6.66c-.23 0-.4-.3-.4-.67v-.87c0-.36.17-.67.4-.67h6.66c.22 0 .41.31.41.67z"
          />
          <path
            className="prefix__cls-1"
            d="M45.54 10.67v-.05a.93.93 0 00-.37-.84L32.42.4a2 2 0 00-2.5.06l-6.13 5V4.08a.32.32 0 00-.31-.32h-2.73a.32.32 0 00-.32.32v4.05l-2.6 2.09a.94.94 0 00-.33.75h-.05v.13c.08 4.56.15 8.3.22 12.87 0 1.44.43 2.47 2.43 2.5v3.2a.93.93 0 00.63.83 1.12 1.12 0 00.4.07 1 1 0 00.69-.27c.76-.69 3.19-2.9 4.36-4L43 26.1a2.6 2.6 0 002.72-2.59c-.04-4.51-.1-8.29-.18-12.84zM43 24.5l-16.75.19a1.82 1.82 0 00-1.28.5c-.78.74-2.09 1.95-2.91 2.7-.06.06-.21 0-.21-.07v-1.51a1.25 1.25 0 00-.43-1 2 2 0 00-.89-.38c-.43-.09-.9-.2-1.06-.65a3.18 3.18 0 01-.07-1.15v-1.75c0-1.1 0-2.21-.05-3.31 0-2.16-.11-4.31-.12-6.46l3.84-3.11.74-.6 7.32-5.92a.17.17 0 01.19 0l12.5 9.19c.07 4.36.13 8 .2 12.42a1 1 0 01-1.02.91z"
          />
          <path
            className="prefix__cls-2"
            d="M39.64 15.77a2.79 2.79 0 00-3.54-.49 4.25 4.25 0 00-.86 6.23 2.79 2.79 0 003.82.53 4.27 4.27 0 00.58-6.27zm-1.42 4.68a1.08 1.08 0 01-1.67-.11 2.58 2.58 0 01.23-3.34 1.09 1.09 0 011.78.27 2.54 2.54 0 01-.34 3.18zM27.77 20.46l7.31-11.65c.31-.5.49-.53 1-.23l.69.41a.62.62 0 010 .81q-1.38 2.08-2.69 4.2-2.43 3.85-4.84 7.72c-.37.59-.51.62-1.1.26-.94-.6-.94-.6-.37-1.52zM29.09 9.12a2.88 2.88 0 00-4.51 0 4.4 4.4 0 00-.34 5.28 2.88 2.88 0 004.08.83 4.3 4.3 0 00.77-6.11zm-1.63 4.5a1 1 0 01-1.59-.09A2.51 2.51 0 0126 10.2a1.08 1.08 0 011.79.15 2.58 2.58 0 01-.33 3.27z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent