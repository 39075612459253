import React from 'react'
import Colors from '../components/colors'
const SvgComponent = props => (
  <svg viewBox="0 0 37.83 63.67" {...props}>
    <defs>
      <style>{`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}</style>
    </defs>
    <title>{'HomeShop'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g
        id="prefix__Real_Estate_Homescapes_Icons"
        data-name="Real Estate &amp; Homescapes Icons"
      >
        <g
          id="prefix__Real_Estate_mobile_marketing"
          data-name="Real Estate mobile marketing"
        >
          <path
            className="prefix__cls-2"
            d="M8.39 24h3.85v1.9c.3-.2.47-.3.62-.42q2.48-1.89 4.93-3.8a1.86 1.86 0 012.44.1l7.08 5.43 5.74 4.41a1.46 1.46 0 01.72 1.47 1.77 1.77 0 01-2.88.8c-1.64-1.24-3.25-2.5-4.88-3.75-2.15-1.65-4.3-3.28-6.43-4.95a.8.8 0 00-1.22 0q-5.61 4.34-11.28 8.64a3 3 0 01-.89.49 1.77 1.77 0 01-1.92-.64 1.35 1.35 0 01.35-1.87c1.1-.86 2.23-1.69 3.31-2.57a1.22 1.22 0 00.44-.83c.04-1.48.02-2.95.02-4.41z"
          />
          <path
            className="prefix__cls-2"
            d="M14.89 32.77h2.71v3.71h-3.7v-2.72a1 1 0 01.99-.99zM13.9 38.7h3.7v3.7h-2.71a1 1 0 01-1-1zM23.93 33.76v2.72h-3.7v-3.71h2.71a1 1 0 01.99.99zM20.23 38.7h3.7v2.71a1 1 0 01-1 1h-2.7z"
          />
          <path
            className="prefix__cls-1"
            d="M37.81 31.84c0 8.86-.05 17.73 0 26.59a5 5 0 01-5.25 5.24c-9.15-.07-18.29-.05-27.43 0A5 5 0 010 58.54c.06-7 0-14 0-21V9c0-1.27.06-2.59 0-3.9A5 5 0 015.17 0h27.55a4.76 4.76 0 015.08 5v26.79zm-1.89 21.92V7H2v46.76zM19 55.72a3 3 0 00-3 3 3 3 0 106 0 3 3 0 00-3-3zM19 4.5h3.75c.76 0 1.21-.37 1.24-1s-.47-1-1.25-1h-7.49c-.79 0-1.21.35-1.22 1s.4 1 1.22 1z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
