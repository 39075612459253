import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../components/colors';
import { useStore } from '../components/useFormState';

const useStyles = makeStyles(theme => ({


    root: {
        margin: '3rem',
        width: '100%'
    },
    field: {
        margin: '0 0 1rem'
    },
    inputRoot: {
        color: Colors.white,
        background: Colors.Accent2,
        fontWeight: 'bold',
        borderRadius: '10px',
        boxShadow: Colors.shadow,
        "&focus": {
            color: Colors.darkAccent1
        }
    },
    inputRootFocused: {
        color: Colors.Accent2,

    },
}));


export default function FormId() {
    const classes = useStyles();
    const { state, dispatch } = useStore();


    const contactFields = [
        { id: "applicant-first-name", label: "First Name", placeholder: "", name: 'firstName', state: state.firstName, type: 'text', focus: true },
        { id: "applicant-last-name", label: "Last Name", placeholder: "", name: 'lastName', state: state.lastName, type: 'text', focus: false },
        { id: "applicant-email", label: "Email", placeholder: "josh@reddinghomeloans.com", name: 'email', state: state.email, type: 'email', focus: false },
        { id: "applicant-phone-number", label: "Phone", placeholder: "(530) 941-0230", name: 'phone', state: state.phone, type: 'phone', focus: false },
    ];





    return (


        <form className={classes.root} noValidate  >
            {contactFields.map((field, index) => (
                <TextField
                    variant="filled"
                    color="primary"
                    fullWidth={true}
                    className={classes.field}
                    autoFocus={field.focus}
                    name={field.name}
                    id={field.id}
                    key={index}
                    type={field.type}
                    label={field.label}
                    select={field.select}
                    required={field.state.required}
                    value={field.state.value}
                    error={field.state.error}
                    helperText={field.state.errorMsg}
                    placeholder={field.placeholder}
                    InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot, focused: classes.inputRootFocused } }}
                    onChange={(e) => { dispatch({ type: "handleChange", name: e.target.name, value: e.target.value }) }}
                    onBlur={(e) => { dispatch({ type: "handleBlur", name: e.target.name, value: e.target.value }) }}
                >
                    {field.options}
                </TextField>


            ))}

        </form>



    )
}