import React from 'react';
import GoogleMapReact from 'google-map-react';
import Colors from './colors';
import Icon from '@material-ui/icons/Place';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: '#000000ad !important',
    color: Colors.white,
    display: 'flex',
    minWidth: '210px',
    borderRadius: '10px',
    padding: '1rem',
    flexDirection: 'column'
  }
}));

export default function SimpleMap() {
  const classes = useStyles();

  const AnyReactComponent = ({ text }) =>
    <Grid container justify='center'>
      <Icon color='secondary' fontSize='large' />
      <div className={classes.box}>
        <Typography variant='h6'>
          Redding Home Loans
        </Typography>
        <Typography variant='body1'>
          2185 Churn Creek Rd, Ste S
          Redding, CA 96002
        </Typography>
      </div>
    </Grid>;


  const defaultProps = {
    center: {
      lat: 40.5757385,
      lng: -122.3523124
    },
    zoom: 18,

  };


  return (
    <div style={{ height: '40vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDfD9rSBxiOhcnxv7zNNfgDMplj-yeG9AE' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={40.5761385}
          lng={-122.3523124}
        />
      </GoogleMapReact>
    </div>
  );

}
