import React, { createContext, useContext, useReducer } from 'react';

const StoreContext = createContext();

const initialState = {
    firstName: {
        name: 'First Name',
        value: '',
        error: false,
        errorMsg: "",
        required: true,
    },
    lastName: {
        name: 'Last Name',
        value: '',
        error: false,
        errorMsg: "",
        required: true,
    },
    email: {
        name: 'Email',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    phone: {
        name: 'Phone Number',
        value: '',
        error: false,
        errorMsg: "",
        required: true,
    },
    residenceType: {
        name: 'Residence',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    residenceTerm: {
        name: 'Residence Duration',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    employmentStatus: {
        name: 'Employment Status',
        value: '',
        error: false,
        errorMsg: "",
        required: true,
    },
    employmentTerm: {
        name: 'Employment Duration',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    creditScore: {
        name: 'Credit Score',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    loanType: {
        name: 'Loan Type',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    mortgageProgram: {
        name: 'Program',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },

    loanAmount: {
        name: 'Loan Amount',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    downpayment: {
        name: 'Downpayment',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },

    term: {
        name: 'Term',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    status: {
        name: 'Status',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },
    homeOwner: {
        name: 'Current Residence',
        value: '',
        error: false,
        errorMsg: "",
        required: false,
    },

}
function validate(action) {
    let errors = {};
    switch (action.name) {
        case 'email':
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(action.value) && action.value !== "") {
                errors.error = true;
                errors.msg = "Invalid email address";
            }
            return errors;
        default:
            return errors.error = false;
    }
}
const reducer = (state, action) => {
    switch (action.type) {
        case "handleChange":
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    value: action.value
                },

            }
        case "handleBlur":
            const results = validate(action);
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    error: results.error,
                    errorMsg: results.msg
                },

            }

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            {children}
        </StoreContext.Provider>
    )
}

export const useStore = () => useContext(StoreContext);