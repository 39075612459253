import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Colors from './colors';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CashOut from '../images/cashout';
import ReduceTerm from '../images/reduceterm';
import ReducePayment from '../images/reducepayment';
import { Link } from 'react-router-dom';
import { useStore } from '../components/useFormState';

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: '3px 7px 9px 2px rgba(0, 0, 0, 0.4), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: '15px',
        margin: '1rem 1rem 2rem'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '32rem'
    },
    header: {
        color: Colors.Accent2,
        margin: '2rem 1rem 1rem',
        fontSize: '2rem',
        fontWeight: '900',
        [theme.breakpoints.down('sm')]: {
            margin: '8rem 1rem 1rem'
        }
    },
    subHeader: {
        color: Colors.Accent2,
        margin: '2rem 1rem 1rem',
        fontSize: '1.5rem',
        fontWeight: '500',
    },
    iconWrapper: {
        padding: '1rem',
        height: '350px',
        margin: '-1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {

    },
    cardButton: {
        color: Colors.white,
        margin: '1rem 0 0'
    }
}));

const cards = [
    [<CashOut style={{ width: '250px' }} />, '', 'Cash Out Equity', `This a great option if you want to increase the property value with renovations or repairs, or if you need to pay off high interest debt.`, 'Apply Now'],
    [<ReducePayment style={{ width: '250px' }} />, '', `Lower Payment`, 'This type of refinance is good for saving money on your monthly payment, empowering you to take back control of your budget', 'Apply Now'],
    [<ReduceTerm style={{ width: '250px' }} />, '', 'Pay Off Faster', `Our favorite refinance options leave you debt free faster and paying less interest over the life of the mortgage.`, `Apply Now`],
];


export default function Timeline() {
    const classes = useStyles();
    const { dispatch } = useStore();

    return (
        <Grid container direction='column' justify='center' align='center'>
            <Typography variant='h2' className={classes.header} gutterBottom>
                What are you wanting to do?
            </Typography>
            <Grid container direction='row' justify='center' >
                {cards.map((card, index) => (
                    <Grid item className={classes.cardContainer} xs={10} sm={6} md={4} lg={3} xl={2} key={index}>

                        <Card className={classes.card}>
                            <CardContent classes={{ root: classes.root }}>
                                <Grid
                                    item
                                    className={classes.iconWrapper}
                                >
                                    {card[0]}
                                </Grid>
                                <Typography variant='h4' className={classes.subHeader} gutterBottom>
                                    {card[2]}
                                </Typography>
                                <Typography variant="body2" color="textPrimary" align='left' component="h6" gutterBottom >
                                    {card[3]}
                                </Typography>
                                <Button
                                    color='secondary'
                                    variant='contained'
                                    fullWidth={true}
                                    size='large'
                                    component={Link} to='/apply'
                                    classes={{ containedSecondary: classes.cardButton }}

                                    onClick={(e) => { dispatch({ type: "handleChange", name: 'loanType', value: 'Refinance' }); dispatch({ type: "handleChange", name: 'status', value: card[2] }) }}
                                >
                                    {card[4]}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}