import React from 'react';
import Colors from '../components/colors'

const SvgComponent = props => (
    <svg viewBox="0 0 111.1 124.18" {...props}>
        <defs>
            <style>{`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}</style>
        </defs>
        <title>{'Asset 10'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g id="prefix__Layer_1-2" data-name="Layer 1">
                <path
                    className="prefix__cls-1"
                    d="M103.89 49.86l.21-1.44c1.41-10.92-.6-19.84-6.16-27.27C88.16 8.07 74.38 1.15 55.82 0h-.55C36.72 1.15 22.94 8.07 13.16 21.15 7.6 28.58 5.59 37.5 7 48.42q.09.72.21 1.44C2.49 51.36 0 55 0 60.29a37.91 37.91 0 004.33 18.08 12.86 12.86 0 009.08 6.7c.1.4.2.75.31 1.1A67.58 67.58 0 0028 110.68c7.91 8.55 16.84 13.21 25.81 13.48h3.45c9-.27 17.91-4.93 25.82-13.48a67.58 67.58 0 0014.3-24.51c.1-.34.21-.7.31-1.1a12.84 12.84 0 009.07-6.69 38 38 0 004.34-18.09c0-5.29-2.49-8.92-7.21-10.43zM98.76 74.1c-.57 1.07-1.47 2.23-4.93 2.32l-3.5.1-.79 3.41c-.11.45-.2.89-.29 1.34-.16.75-.33 1.54-.54 2.21C84.84 96 72.08 114.64 57 115.09h-2.89C39 114.64 26.25 96 22.38 83.47c-.2-.65-.36-1.4-.55-2.29-.08-.41-.17-.83-.27-1.25l-.79-3.41-3.5-.1c-3.46-.09-4.36-1.25-4.94-2.32a29 29 0 01-3.26-13.81c0-1.66 0-1.76 2.11-2.06.57-.07 1.11-.18 1.65-.29l4.78-.94-.72-4.36c-.1-.57-.19-1.11-.28-1.64l-.07-.37c-.2-1.19-.4-2.32-.54-3.4-1.1-8.46.35-15.22 4.42-20.66C28.47 15.82 40 10.1 55.55 9.09c15.59 1 27.08 6.73 35.13 17.5 4.07 5.41 5.52 12.2 4.42 20.65-.14 1.11-.35 2.27-.54 3.4v.17c-.11.59-.21 1.21-.32 1.85L93.49 57l4.85.93c.47.1 1 .21 1.56.28 2.13.3 2.13.4 2.13 2.05a29 29 0 01-3.27 13.84z"

                />
                <path
                    className="prefix__cls-2"
                    d="M67.5 97.48a22.47 22.47 0 00-10-3.25A27.94 27.94 0 0044 97.31 3.05 3.05 0 0042.32 99a2.13 2.13 0 00.22 1.68c.65 1.23 2 1.56 3.45.81 6.69-3.44 13.13-3.41 19.27.06a2.68 2.68 0 001.32.36A2.33 2.33 0 0068.8 99a2.6 2.6 0 00-1.3-1.52zM49 80.52a2.35 2.35 0 00-.49 3.42 8.1 8.1 0 006.86 3.84 8.22 8.22 0 007.4-4.11 2.27 2.27 0 00-.63-3.18 2.24 2.24 0 00-3.15.66c-2 2.53-4.78 2.57-6.76.1-.99-1.25-2.18-1.48-3.23-.73zM55.54 63A3.43 3.43 0 0058 62a3.4 3.4 0 10-5.85-2.37A3.44 3.44 0 0055.54 63zM44.53 35l4.24.53a6.1 6.1 0 00.69.07c.31 0 .39-.06.66-.88a5.41 5.41 0 015.3-4.13A5.09 5.09 0 0160 33.06c.48.93.74 2.44-.67 4.37A11.92 11.92 0 0156.49 40a9.13 9.13 0 00-3.24 3.45c-.94 2.07-1 4.95-.88 7.49 0 .37.1.47.46.47h4.79c.43 0 .52-.09.55-.11a.7.7 0 00.12-.53c0-1.3.05-2.49.15-3.64.1-1.54 1.15-2.47 2.17-3.37l1-.93c2.59-2.29 5.44-4.8 5.46-8.25A8.66 8.66 0 0064 28.11 10.92 10.92 0 0060.33 26a13.54 13.54 0 00-4.51-.74c-5.44 0-11.25 3.13-11.82 9.11 0 .4 0 .63.53.63zM34.43 38.47a1.24 1.24 0 00-1.85-1.07l-7.71 5.68a1.24 1.24 0 000 2.13l7.71 5.68a1.24 1.24 0 001.85-1.07v-3.33H41V41.8h-6.57zM86.22 43.08l-7.7-5.68a1.24 1.24 0 00-1.85 1.07v3.33h-6.62v4.69h6.62v3.33a1.24 1.24 0 001.85 1.07l7.7-5.68a1.23 1.23 0 000-2.13z"
                />
            </g>
        </g>
    </svg>
)

export default SvgComponent
