import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import logo from '../images/logo.png';
import logo2 from '../images/logo@2x.png';
import logo3 from '../images/logo@3x.png';


import Map from '../components/map';
import Calculator from '../components/calculator';

import FacebookIcon from '@material-ui/icons/Facebook';

import { Link as RouterLink } from 'react-router-dom';

import Colors from '../components/colors';

const useStyles = makeStyles(theme => ({
    footerContainer: {
        background: Colors.dark,
        color: Colors.white,

    },
    footerCTA: {
        background: Colors.Accent2,
        color: Colors.white,
        justifyContent: 'center',
        padding: '1rem 0',
    },
    footerCTA__p: {
        lineHeight: '3',
        padding: '0 1rem'
    },
    footerCTA__button: {
        color: Colors.white
    },
    footerContactUs: {
        color: Colors.white,
        justifyContent: 'center',
        padding: '0rem 1rem',
    },
    footerBody: {

        padding: '2rem 0',
    },
    footerItems: {
        padding: '1rem',
    },
    footerItemsOther: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    footerLogo: {

        minHeight: '30px',
        maxWidth: '80vw',
        maxHeight: '5vh',
        padding: '1rem 0',
    },
    footerCopyRights: {
        background: '#111',
        padding: '1rem',
        marginBottom: '3rem'
    },
}));

const footerSubMenu2 = [
    ['Home', '/'],
    ['Purchase', '/purchase'],
    ['Refinance', '/refinance'],
    ['Rates', '/rates'],
    ['Privacy', '/privacy'],
];
const footerSubMenu3 = [
    ['Corp. CalDRE: 01902537', 'http://www2.dre.ca.gov/PublicASP/pplinfo.asp?License_id=01902537'],
    ['Broker CalDRE: 01856795', 'http://www2.dre.ca.gov/PublicASP/pplinfo.asp?License_id=01856795'],
    ['Company NMLS ID: 815819', 'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/815819'],
    ['Individual NMLS ID: 264779', 'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/INDIVIDUAL/264779'],
];



export default function Footer() {
    var today = new Date();
    var year = today.getFullYear();
    const classes = useStyles();
    const smallOrBelow = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const Logo = () => (
        <Grid item component={RouterLink} to='/' md={4}>
            <img alt='rhl logo' className={classes.footerLogo} src={logo} srcSet={`${logo} 300w, ${logo2} 768w, ${logo3} 1280w`} />
        </Grid>
    );
    return (
        <Grid container className={classes.footerContainer}>
            <Calculator />
            <Map />
            <Grid container className={classes.footerCTA}>
                <Typography className={classes.footerCTA__p} variant='h6'>
                    Great service with better rates!
                </Typography>
                <Button
                    variant='contained'
                    size='large'
                    color='secondary'
                    component={RouterLink}
                    to={'/apply'}
                    classes={{ label: classes.footerCTA__button }}
                >
                    Start Now
                </Button>
            </Grid>
            <Grid container className={classes.footerBody} wrap={smallOrBelow ? 'wrap' : 'nowrap'} justify={smallOrBelow ? 'space-around' : 'center'} >

                <Grid item xs={12} md={6} lg={4} className={classes.footerItems}>
                    <Logo />
                    <Typography>
                        Our mission is to provide the great service you expect and better rates than our competition. Let us serve your home financing needs.
                    </Typography>
                    <Grid item component='a' href='https://www.facebook.com/MyReddingHomeLoans/'>
                        <FacebookIcon color='secondary' />
                    </Grid>
                </Grid>

                <Grid item sm={6} md={2} lg={3} xl={2} className={smallOrBelow ? classes.footerItemsOther : classes.footerItems}>
                    <Typography variant='h6'>
                        Quick Find
                    </Typography>
                    {footerSubMenu2.map(menuItem => (
                        <Grid item key={menuItem[0]} >
                            <Link component={RouterLink} to={menuItem[1]} color='secondary'>
                                {menuItem[0]}
                            </Link>
                        </Grid>
                    ))}
                    <a href='tel:53094190230' className={classes.footerContactUs}>Call Us (530) 941-0230</a>
                </Grid>
                <Grid item sm={6} md={2} lg={3} xl={2} className={smallOrBelow ? classes.footerItemsOther : classes.footerItems}>
                    <Typography variant='h6'>
                        Other Info
                    </Typography>
                    {footerSubMenu3.map(menuItem => (
                        <Grid item key={menuItem[0]}>
                            <a href={menuItem[1]} style={{ color: Colors.darkAccent1 }}>
                                {menuItem[0]}
                            </a>
                        </Grid>
                    ))}
                </Grid>

            </Grid>
            <Grid container justify='center' direction='row' alignItems='center' className={classes.footerCopyRights}>

                <Typography>© {year} Redding Home Loans All Rights Reserved | Professional Mortgage Associates DBA Redding Home Loans.</Typography>

            </Grid>
        </Grid>
    );
}