import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Colors from './colors';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import { useEffect } from 'react';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    margin: '2rem 1rem 4rem',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,

  },
  header: {
    margin: '1rem 0 3rem'
  },
  gridList: {
    flexWrap: 'nowrap',
    borderRadius: '20px',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: '#ffca00',
    fontSize: '2rem',
    lineHeight: 1,
    whiteSpace: 'normal',
    fontWeight: 'bold',

  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',

  },
  subtitle: {
    fontSize: '1rem',
    whiteSpace: 'normal',
    fontWeight: '500',
    textOverflow: 'ellipsis',
    lineHeight: 1,
    maxWidth: '90%',

  },
  rootSubtitle: {
    height: '50%',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    pointerEvents: 'none',
  },
  wrapAction: {
    overflow: 'visible'
  },
  rating: {
    padding: '1rem'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '90vw',
    maxHeight: '70vh',
    margin: '8rem auto',
    borderRadius: '15px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '70vw',
      margin: '8rem auto',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '55vw',
      margin: '8rem auto',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '40vw',
      margin: '8rem auto',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '15px',
    boxShadow: theme.shadows[5],
    maxHeight: '80vh',
    flexWrap: 'nowrap',

    overflowY: 'scroll',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    }
  },
  card__img: {
    objectFit: 'cover',
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    border: `10px solid ${Colors.lightAccent1}`,
    [theme.breakpoints.down('md')]: {
      width: '100px',
      height: '100px',
    }
  },
  card__bar: {
    background: Colors.Accent2,
    flexBasis: '350px',
    color: 'white',
    textAlign: 'center',
    padding: '2em',

    maxHeight: '45vh',
    [theme.breakpoints.down('md')]: {
      maxHeight: '25vh',
    }
  },
  card__body: {
    padding: '2rem',
    maxHeight: '45vh',
    overflowY: 'scroll',
  },
  card__author: {
    letterSpacing: '1px',
    fontSize: '2rem',
    margin: '.75em 0 0',
    lineHeight: '1',
    '&:after': {
      content: '""',
      display: 'block',
      width: '2em',
      height: '1px',
      background: '#5bcbf0',
      margin: '.5em auto .65em',
      opacity: '.25',

    },
    [theme.breakpoints.down('md')]: {
      margin: '-.5rem',
    }
  },
  review__title: {
    color: Colors.Accent2,
    fontSize: '2rem',
    // font-weight: '300',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    lineHeight: '1',
    margin: '0',
  },
  review__text: {
    fontSize: '1.25rem'
  },
  review__buttons: {
    [theme.breakpoints.down('md')]: {
      position: 'sticky',
      bottom: '-5vh',
      background: 'linear-gradient(0deg, white 50%, transparent)',
      width: '89vw',

      padding: '1rem 2rem 2rem',
    }
  }
}));



export default function ImageGridList() {
  const classes = useStyles();
  const smallOrBelow = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);
  const [reviewIndex, setIndex] = React.useState(0);
  const [Data, setData] = React.useState(null);

  const handleClick = (e) => {
    let i = Number.parseInt(e.target.dataset.index);
    setIndex(i);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlePrev = () => {
    let x = Number.parseInt(reviewIndex);
    let i = (x - 1) < 0 ? Data.length - 1 : x - 1;
    setIndex(i)
  }
  const handleNext = () => {
    let ci = Number.parseInt(reviewIndex);
    let i = (ci + 1) === Data.length ? 0 : ci + 1;
    setIndex(i)
  }


  const getReviews = async () => {
    const url = 'https://storage.googleapis.com/redding-home-loans/reviews.json';
    const params = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'applications/json'
      }
    }
    const response = await fetch(url, params);
    const json = await response.json();


    return Promise.all(json);
  }

  useEffect(() => {
    if (Data === null) {
      getReviews().then(
        (d) => {
          if (d.length > 5) {
            setData(d);
          }
        }
      );
    }

  });

  if (Data === null) { return (<div />); }

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card className={classes.paper}>
            <Grid className={classes.card__bar}>
              <img src={Data[reviewIndex].img} alt='kitteh' className={classes.card__img} />
              <p className={classes.card__author}>{reviewIndex > -1 && Data[reviewIndex].author}</p>
              <div style={{ margin: '-.5rem' }}>{reviewIndex > -1 && <Rating name="size-large" value={Data[reviewIndex].rating} size="large" />}</div>
            </Grid>
            <Grid container justify='space-between' alignItems='center' direction='column' wrap='nowrap' className={classes.card__body} >
              <Grid className="a">
                <p className={classes.review__title}>{reviewIndex > -1 && Data[reviewIndex].title}</p>
                <p className={classes.review__text}>{reviewIndex > -1 && Data[reviewIndex].review}</p>
              </Grid>
              <Grid container justify='space-between' alignItems='flex-start' wrap='nowrap' className={classes.review__buttons}>
                <Button variant='contained' color='primary' onClick={handlePrev}>prev</Button>
                <Button variant='contained' color='primary' onClick={handleNext}>Next</Button>
              </Grid>
            </Grid>
          </Card>
        </Fade>
      </Modal>
      <Typography variant='h3' color='primary' className={classes.header} gutterBottom>
        Reviews
        </Typography>
      <GridList
        className={classes.gridList}
        cellHeight={315}
        cols={smallOrBelow ? 1 : 5}
      >
        {Data.map((tile, index) => (
          <GridListTile
            key={index}
          >
            <img
              src={tile.img}
              data-index={index}
              onClick={handleClick}
              alt={tile.title}
            />
            <GridListTileBar
              aria-label='Click Stars to read full review.'
              title={tile.title}
              classes={{
                root: classes.titleBar,
                title: classes.title,
                subtitle: classes.subtitle,
                rootSubtitle: classes.rootSubtitle,
                titleWrapActionPosRight: classes.wrapAction
              }}
              subtitle={tile.review}
              actionIcon={
                <Box
                  classes={{ root: classes.rating }}
                  aria-label={`${tile.rating} star rating`}
                >
                  <Rating name="size-large" value={tile.rating} size="large" />
                </Box>
              }
            />
          </GridListTile>
        ))}
      </GridList>

    </div>

  );


}