import React from 'react';
//import { withStyles, makeStyles } from '@material-ui/core/styles';
import Rates from '../components/rates';
import Grid from '@material-ui/core/Grid';
//import Colors from '../components/colors';




export default function CustomizedTables() {


    return (
        <Grid container justify='center' alignItems='center'>
            <Rates />
        </Grid>
    );
}
