import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LoopIcon from '@material-ui/icons/Loop';
import HouseIcon from '@material-ui/icons/House';
import InputIcon from '@material-ui/icons/Input';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
//import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
//import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import SpeedDial from '../components/speedDial';
import BottomNav from '../components/bottomNav';
import Colors from './colors';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  menuRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    overflowX: 'auto',

  },
  menuButton_scrolled: {
    position: '',
    right: '0',
    top: '0',
    filter: 'drop-shadow(1px 3px 3px black)',
    margin: '.2em 0',
    color: Colors.white,
  },
  menuButton_unscrolled: {
    position: '',
    right: '0',
    top: '0',
    margin: '.2em 0',
    color: Colors.dark,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    paddingLeft: '0px',
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  callUs: {
    position: 'fixed',
    bottom: '4vh',
    right: '6vw',
    color: Colors.white,
    border: 'solid ',
    fontSize: '1.1em',
    filter: 'drop-shadow(1px 3px 3px black)',
    background: Colors.dark,
    boxShadow: '0 0 0 .5rem #222'

  }
}));

export default function NavMenu(props) {

  const scrolled = props.scrolled;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const links = [['Purchase', '/purchase', <HouseIcon />], ['Refinance', '/refinance', <LoopIcon />], ['Rates', '/rates', <MonetizationOnIcon />], ['Apply', '/apply', <InputIcon />],];

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <div className={classes.menuRoot}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/*<IconButton  
          className={classes.callUs} 
          href="tel:5309410230"
        >
          <ContactSupportIcon />
        </IconButton>*/}
          {/*<SpeedDial />*/}
          <BottomNav />
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(open && classes.hide, !!scrolled ? classes.menuButton_scrolled : classes.menuButton_unscrolled)}
          >
            <MenuIcon />
          </IconButton>
        </div>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <List>
              <ListItem button onClick={handleDrawerClose} component={Link} to='/' key='home'>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary='Home' />
              </ListItem>
            </List>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {links.map((text, index) => (
              <ListItem button onClick={handleDrawerClose} component={Link} to={text[1]} key={text[0]}>
                <ListItemIcon>{text[2]}</ListItemIcon>
                <ListItemText primary={text[0]} />
              </ListItem>
            ))}

          </List>
          {/* <Divider />
          <List>
            <ListItem button key='login' onClick={handleDrawerClose}>
              <ListItemIcon><PersonIcon /></ListItemIcon>
              <ListItemText primary='login' />
            </ListItem>
          </List> */}
        </Drawer>

      </div>
    </ClickAwayListener>
  );
}
