import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../components/colors';
import { useStore } from '../components/useFormState';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({



    field: {
        margin: '.5rem 1rem'
    },

    alt: {
        '&:nth-of-type(even)': {
            backgroundColor: Colors.owbg,
        },
        '&:nth-of-type(odd)': {
            backgroundColor: 'white',
        },
    },

}));


export default function FormReview() {

    const classes = useStyles();

    const { state } = useStore();

    return (
        <Grid container direction='column' justify='center'>
            <Typography>
                Please review your submission.
            </Typography>
            <div >
                {Object.keys(state).filter((key) => (!!state[key].value)).map((key, index) => (
                    <Grid key={index} container className={classes.alt} justify='space-between'>
                        <Typography className={classes.field}>
                            {state[key].name}:
                        </Typography>
                        <Typography className={classes.field}>
                            {state[key].value}
                        </Typography>
                    </Grid>
                ))}
            </div>
        </Grid>
    )
}