import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';
import Journey from '../components/journey';
import Programs from '../components/programs';
import bg from '../images/heroBG.jpg';
import bg2 from '../images/heroBG@2x.jpg';
import bg3 from '../images/heroBG@3x.jpg';

import Colors from '../components/colors';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Reviews from '../components/reviews';
//import Triangle from '../images/triangle';

const useStyles = makeStyles(theme => ({
    background: { background: Colors.owbg },
    hero: {
        margintop: '',
        height: '100vh',
        width: '100%',
        position: 'relative',/*
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',*/
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'top center',
            backgroundSize: 'cover',
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${bg2})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${bg3})`,
            backgroundPosition: 'center 0px center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
    },
    triangle: {
        position: 'absolute',
        bottom: '0'
    },
    accent: {
        color: Colors.darkAccent1
    },
    journeyPaper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    whitePaper: {
        padding: '1rem',
        width: '100%',
        position: '',
        background: Colors.white,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    Paper: {
        background: Colors.white,
        width: '100%',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    hero__cta__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
        color: Colors.dark,
        padding: '1.25rem',
        boxShadow: '0 10px 30px rgba(51,51,51,.9)',
        [theme.breakpoints.down('sm')]: {
            marginTop: '15vh',
            background: Colors.white,
            maxWidth: '85vw',
            borderRadius: '15px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '20vh',
            background: Colors.white,
            maxWidth: '65vw',
            borderRadius: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '20vh',
            background: Colors.white,
            maxWidth: '45vw',
            borderRadius: '15px',
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: '30vh',
            background: Colors.white,
            maxWidth: '30vw',
            borderRadius: '15px',
        },
    },
    hero__cta__h1: {
        marginBottom: '0',
        fontWeight: '900',


    },
    hero__cta__tag: {
        fontWeight: '700',
        fontSize: '1.5rem',
        marginBottom: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
        hero__cta__buttons: {
            margin: '1rem 1vw',
            fontSize: '1rem',
            padding: '1rem 4rem',
            background: Colors.Accent2, //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))',
            color: Colors.white,
            textTransform: 'none',
            border: `solid ${Colors.Accent2}`,
            "&:hover": {
                color: Colors.Accent2,
                background: Colors.white,
                border: `solid ${Colors.Accent2}`
            }

        },
    },

    [theme.breakpoints.down('md')]: {
        hero__cta__buttons: {
            margin: '1rem 1vw',
            fontSize: '1rem',
            padding: '1rem 2rem',
            background: Colors.Accent2, //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))',
            color: Colors.white,
            textTransform: 'none',
            border: `solid ${Colors.Accent2}`,
            "&:hover": {
                color: Colors.Accent2,
                background: Colors.white,
                border: `solid ${Colors.Accent2}`
            }
        },
    },
    mobileH1: {
        fontSize: '2.5rem'
    }
}));






export default function Home() {
    const classes = useStyles();


    return (
        <Grid container justify='center' className={classes.background}>
            <Grid item sm={12} className={classes.hero}>
                <Card className={classes.hero__cta__container}>
                    <Typography variant='h1' classes={{ h1: classes.mobileH1 }} className={classes.hero__cta__h1}>The Local Lender with <span className={classes.accent}>Better Rates</span></Typography>
                    <p className={classes.hero__cta__tag}>Get the rates you need and the service you expect.</p>
                    <Grid container wrap='nowrap' justify='center' >
                        <Button className={classes.hero__cta__buttons} component={Link} to='/purchase'>
                            Purchase
                            </Button>
                        <Button className={classes.hero__cta__buttons} component={Link} to='/refinance'>
                            Refinance
                            </Button>
                    </Grid >
                </Card>
                {/* <Triangle className={classes.triangle} /> */}
            </Grid>
            <Journey />
            <Programs />
            <Reviews />


        </Grid>
    );

}
