import React from 'react'
import Colors from '../components/colors'
const SvgComponent = props => (
  <svg viewBox="0 0 59.96 62.65" {...props}>
    <defs>
      <style>{`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}</style>
    </defs>
    <title>{'Escrow'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g
        id="prefix__Real_Estate_Homescapes_Icons"
        data-name="Real Estate &amp; Homescapes Icons"
      >
        <path
          className="prefix__cls-2"
          d="M17.7 19h3.12a.82.82 0 01.82.82v1.9c.37-.25.57-.38.76-.52l6.1-4.7a2.29 2.29 0 013 .12l8.76 6.72q3.54 2.73 7.09 5.45a1.78 1.78 0 01.88 1.82 2.18 2.18 0 01-3.55 1c-2-1.52-4-3.08-6-4.63-2.65-2-5.32-4.06-7.94-6.13a1 1 0 00-1.51 0q-6.94 5.37-13.94 10.69a3.85 3.85 0 01-1.11.6 2.18 2.18 0 01-2.37-.79 1.69 1.69 0 01.43-2.31C13.57 28 15 27 16.31 25.88a1.52 1.52 0 00.54-1v-5a.81.81 0 01.85-.88z"
        />
        <path
          className="prefix__cls-1"
          d="M44.43 33.81l-14.5-10.58h-.05L15.34 33.81l-1.25.9v9c.4.14.81.28 1.22.4l1.43.49v-7.82a1.3 1.3 0 01.55-1.06l.33-.24 11.48-8.37a1.32 1.32 0 011.56 0l11.5 8.32.32.24a1.32 1.32 0 01.52 1.06v9.3c.6-.19 1.2-.39 1.73-.52a8.87 8.87 0 01.91-.2v-10.6z"
        />
        <path
          className="prefix__cls-2"
          d="M26.05 32.5h2.58V36H25.1v-2.56a1 1 0 01.95-.94zM25.1 38.15h3.53v3.52h-2.58a1 1 0 01-.95-.94zM34.66 33.44V36h-3.53v-3.5h2.59a.94.94 0 01.94.94zM31.13 38.15h3.53v2.58a.94.94 0 01-.94.94h-2.59z"
        />
        <path
          className="prefix__cls-1"
          d="M52.46 51a40 40 0 00-9.55 4.35c-4.11 2.82-12.29 5.92-12.29 5.92a16 16 0 01-14.42-.66 15.22 15.22 0 01-3.9-2.12 8.79 8.79 0 00-5.75-1.4c-.08.12-1 .2-1.11.24a1.28 1.28 0 01-1.15-.51l-4-5.41a1.18 1.18 0 01-.19-.32 1.08 1.08 0 010-.34 1 1 0 010-.24A1.29 1.29 0 01.18 50a29.28 29.28 0 016.4-3.56c2.67-.79 5.55-1.39 8.31-.68 1.55.4 3.07 1 4.61 1.49 3.87 1.2 7.6 3 11.86 2.53 1.78-.18 3.53-.48 5.31-.67a3.19 3.19 0 01.53-.06 8.45 8.45 0 012 0 5 5 0 01.65.13 1.12 1.12 0 011 1.09c0 1-1.19 1.17-1.92 1.4-1 .29-2.07.64-3.1.9a12.66 12.66 0 01-1.42.31c-1.48.24-2.94.41-4.42.52-.55 0-1.08.1-1.63.12s-.9 0-1.34 0a5.68 5.68 0 00-1.54 0c-1.14.34-.45.93.25 1.28 2.54 1.27 6 .53 8.63 0a24.35 24.35 0 004-1.26 3.84 3.84 0 01.36-.14s5.85-1.45 3-5.09c.9-.28 2.06-.68 3-1s2-.29 2.4.8a3.1 3.1 0 01.49-.06c2.58.08 3 .63 3 1.1 3.64.36 1.85 1.85 1.85 1.85zM7.51 11.68a40.28 40.28 0 009.54-4.34c4.12-2.83 12.29-5.92 12.29-5.92a15.93 15.93 0 0114.42.66 14.81 14.81 0 013.9 2.11 8.79 8.79 0 005.75 1.4c.08-.12 1-.2 1.11-.24a1.32 1.32 0 011.16.51l4 5.41a1 1 0 01.18.32.9.9 0 01.06.34 1.92 1.92 0 010 .24 1.6 1.6 0 01-.17.44 29.71 29.71 0 01-6.4 3.55c-2.68.79-5.56 1.4-8.31.69-1.56-.4-3.08-1-4.62-1.5-3.86-1.2-7.6-3-11.85-2.53-1.79.19-3.54.48-5.32.68-.17 0-.35 0-.52.06a8.54 8.54 0 01-2 0 5.26 5.26 0 01-.65-.12 1.15 1.15 0 01-1-1.09c.05-1 1.19-1.18 1.92-1.4 1-.3 2.07-.64 3.1-.91a12.22 12.22 0 011.43-.3c1.47-.24 2.93-.42 4.41-.53.55 0 1.08-.09 1.63-.11s.91 0 1.34 0a5.68 5.68 0 001.54 0c1.15-.34.45-.92-.25-1.28-2.54-1.27-6-.52-8.63 0a24.38 24.38 0 00-4 1.27l-.35.14s-5.86 1.45-3 5.08c-.91.29-2.07.69-3 .95s-2 .3-2.4-.8v-.05a3.1 3.1 0 01-.49.06c-2.58-.08-3-.63-2.95-1.1-3.67-.23-1.87-1.69-1.87-1.69z"
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
