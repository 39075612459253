import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import bg from '../images/purchaseHeroBG.jpg';
import bg2 from '../images/purchaseHeroBG@2x.jpg';
import bg3 from '../images/purchaseHeroBG@3x.jpg';


const useStyles = makeStyles(theme => ({
    hero: {
    margintop: '',
    height: '80vh',
    width: '100%',
    position: 'relative',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
        marginBottom: '40vh',
        '&::before': {
            content: '""',
            background: 'linear-gradient(rgba(0,0,0,.0) 5%, rgba(0,0,0,0)) 95%',
            width: '100%',
            height: '80vh',
            display: 'block',
        },
        },
        [theme.breakpoints.up('md')]: {
        backgroundImage: `url(${bg2})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        marginBottom: '40vh',
        '&::before': {
            content: '""',
            background: 'linear-gradient(rgba(0,0,0,.0) 5%, rgba(0,0,0,0)) 95%',
            width: '100%',
            height: '80vh',
            display: 'block',
        },
        },
        [theme.breakpoints.up('lg')]: {
        backgroundImage: `url(${bg3})`,
        backgroundPosition: 'center 0px center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginBottom: '40vh',
        '&::before': {
            content: '""',
            background: 'linear-gradient(rgba(0,0,0,.0) 5%, rgba(0,0,0,0)) 95%',
            width: '100%',
            height: '80vh',
            display: 'block',
        },
        },
    },
    red: {
        color: 'rgb(185, 79,79)'
    },
    journeyPaper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '4vh 4vw',
        width: '100%',
        margin: '0 auto',
        position: '',
        background: 'rgb(185,79,79)',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    whitePaper: {
        margintop: '',
        minHeight: '80vh',
        width: '100%',
        position: '',
        background: '#f2f2f2',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    darkPaper: {
        margintop: '',
        height: '80vh',
        width: '100%',
        position: 'relative',
        background: '#999',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    hero__cta__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 0 0 20vw',
        color: 'black',
        padding: '1rem',
        boxShadow: '0 10px 30px rgba(51,51,51,.9)',
        [theme.breakpoints.down('sm')]: {
        marginTop: '-65vh',
        background: 'white',
        maxWidth: '20vw',
        borderRadius: '15px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '-55vh',
            background: 'white',
            maxWidth: '20vw',
            borderRadius: '5px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '-55vh',
            background: 'white',
            maxWidth: '20vw',
            borderRadius: '5px',
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: '-55vh',
            background: 'white',
            maxWidth: '20vw',
            borderRadius: '5px',
        },
    },
    hero__cta__h2: {
        margin: '1rem 1rem 0',
        fontWeight: '900',
        "&::before": {
            color: 'white',
            fontSize: '1.5rem',
            display: '',
            margin: '0 .2rem 0',
            content: '""',
            padding: '0px .2rem',
            background: 'rgb(54,123,102)', //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))', linear-gradient(rgb(139, 143, 109),rgb(62, 73, 33))
        },
        "&::after": {
            color: 'white',
            fontSize: '1.5rem',
            display: '',
            margin: '0 .2rem 0',
            content: '""',
            padding: '0px .2rem',
            background: 'rgb(54,123,102)', // 'linear-gradient(rgb(167, 0, 0), rgba(255, 0, 0, 0.9))', linear-gradient(rgb(62, 73, 33), rgb(139, 143, 109)
        }     
    },
    hero__cta__tag: {
        fontWeight: '500',
        fontSize: '1.25rem',
        margin: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
        hero__cta__buttons: {
            margin: '1rem 1vw',
            fontSize: '1rem',
            padding: '1rem 4rem',
            background: 'rgb(54,123,102)', //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))',
            color: 'white',
            textTransform: 'none',
            "&:hover": {
                color: '#222',
                background: '#fff',
                border: 'solid #222'
            }
    
        },
    },
    
    [theme.breakpoints.down('md')]: {
        hero__cta__buttons: {
            margin: '1rem 1vw',
            fontSize: '1rem',
            padding: '1rem 2rem',
            background: 'rgb(54,123,102)', //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))',
            color: 'white',
            textTransform: 'none', 
            "&:hover": {
                color: '#222',
                background: '#fff',
                border: 'solid #222'
            }
        },
    },
    journey__h2: {
        marginBottom: '0',
        fontWeight: '900',
        "&::before": {
            color: 'white',
            fontSize: '1.5rem',
            display: '',
            margin: '0 .2rem 0',
            content: '""',
            padding: '0px .2rem',
            background: '#222', //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))', linear-gradient(rgb(139, 143, 109),rgb(62, 73, 33))
        },
        "&::after": {
            color: 'white',
            fontSize: '1.5rem',
            display: '',
            margin: '0 .2rem 0',
            content: '""',
            padding: '0px .2rem',
            background: '#222', // 'linear-gradient(rgb(167, 0, 0), rgba(255, 0, 0, 0.9))', linear-gradient(rgb(62, 73, 33), rgb(139, 143, 109)
        }     
    },
})); 




export default function Home(){

    const classes = useStyles();
        return (
            <Grid container spacing={4}>
                <Grid item sm={12} className={classes.hero}>
                   <Grid item md={6} >
                   <div className={classes.hero__cta__container}>
                        <h1 className={classes.hero__cta__h2}>Purchase a <span className={classes.red}>Home.</span></h1>
                        <p className={classes.hero__cta__tag}>Our process begins with a Pre-Approval, so you can shop for your new home with confidence.</p>
                        <div >
                        <Button className={classes.hero__cta__buttons}>
                                Get Started
                            </Button>
                           
                        </div>
                    </div>
                   </Grid>
                </Grid>
               
               

            </Grid>
        );
    
}
