import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../components/colors';
import Card from '@material-ui/core/Card';
import Submit from '../components/submit';
import ID from '../components/formIdInfo';
import Finance from '../components/formFinanceInfo';
import Residence from '../components/formResidenceInfo';
import { useStore } from '../components/useFormState';
import Mortgage from '../components/formMortgageInfo';
import Review from '../components/formReview';

const useStyles = makeStyles(theme => ({

    hero__cta__container: {
        display: 'flex',
        position: 'relative',
        zIndex: '999',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        color: Colors.dark,
        padding: '1.25rem',
        boxShadow: Colors.shadow,
        overflow: 'visible',
        borderRadius: '15px',

        [theme.breakpoints.down('sm')]: {
            marginTop: '15vh',
            maxWidth: '85vw',
            minWidth: '85vw',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '20vh',
            maxWidth: '65vw',
            minWidth: '65vw',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '20vh',
            maxWidth: '45vw',
            minWidth: '45vw',
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: '30vh',
            maxWidth: '30vw',
            minWidth: '30vw',
        },
    },
    hero__cta__h1: {
        margin: '0rem 0 1rem',
        fontWeight: '900',
        fontSize: '2rem'


    },
    hero__cta__tag: {
        fontWeight: '700',
        fontSize: '1.5rem',
        marginBottom: '1rem',
    },
    accent: {
        color: Colors.darkAccent1
    },

}));


export default function Apply() {
    const [stage, setStage] = React.useState(1);
    const classes = useStyles();
    const { state } = useStore();

    const disabled = () => {
        let count = 0;
        Object.keys(state).filter((key, index) => index <= 3).forEach((key) => {
            state[key].error && count++;
            state[key].required && !state[key].value && count++
        })
        return !!count ? true : false
    }

    const handleNext = () => {
        let next = stage + 1
        setStage(next)
    }
    const handleBack = () => {
        let next = stage - 1
        setStage(next)
    }

    const handleApply = () => {
        const payload = {}
        Object.keys(state).forEach((key) => { payload[key] = state[key].value })
        Submit(payload)
            .then((data) => {
                //console.log(data); // JSON data parsed by `response.json()` call
            });
        handleNext();
    }



    return (

        <Card className={classes.hero__cta__container}>
            {stage < 5 &&
                <Typography variant='h1' classes={{ h1: classes.mobileH1 }} className={classes.hero__cta__h1}>
                    Your new life begins with <span className={classes.accent}>Better Rates</span>
                </Typography>
            }

            {stage < 5 &&
                <Typography>
                    Fill Out the form below to begin your application.
                </Typography>
            }
            {stage === 1 && <ID />}

            {stage === 2 && <Mortgage />}

            {stage === 3 && <Finance />}

            {stage === 4 && <Residence />}

            {stage === 5 && <Review />}

            {stage === 6 &&
                <Typography variant='h1' classes={{ h1: classes.mobileH1 }} className={classes.hero__cta__h1}>
                    <span className={classes.accent}>Thank you </span>for submitting your application.
                </Typography>
            }

            {stage < 6 && < ButtonGroup >
                {
                    stage > 1 &&
                    <Button
                        variant='contained'
                        fullWidth={true}
                        color='secondary'
                        onClick={handleBack}
                        type="submit"
                    >
                        Back
                    </Button>
                }
                <Button
                    variant='contained'
                    disabled={disabled()}
                    fullWidth={true}
                    color='secondary'
                    onClick={stage === 5 ? handleApply : handleNext}
                    type="submit"
                >
                    {stage === 5 ? 'Submit Application' : 'Next'}
                </Button>

            </ButtonGroup>}
        </Card >

    )
}