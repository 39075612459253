import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
//import Tooltip from "@material-ui/core/Tooltip";
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { VictoryPie, VictoryLabel, VictoryLegend, VictoryTheme, } from 'victory';

import Colors from './colors';

//icons
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HomeIcon from '@material-ui/icons/Home';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';

//snackbar
import CustomizedSnackbars from './snackbar'


const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.25rem',
        padding: '3rem 0',
        background: Colors.owbg,
        alignItems: 'center',
        color: Colors.Accent2,
        flexBasis: '100%',
    },
    input: {
        borderColor: 'rgba(255, 255, 255, 0.8)',
        boderWidth: '2px'
    },
    calcContainer: {
        fontSize: '1.25rem',
        borderRadius: '20px'
    },
    calcBody: {
        padding: '2rem'
    },
    items: {
        background: Colors.Accent2,
        margin: '1rem',
        borderRadius: '15px',
        boxShadow: Colors.shadow,
        [theme.breakpoints.down('sm')]: {
            margin: '.5rem'
        },
    },
    vaOptions: {
        background: Colors.darkAccent1,
        color: Colors.white,
        borderRadius: '.5rem',
        justifyContent: 'space-around',
        margin: '-2rem 1rem 1rem',
        padding: '0 1rem'
    },
    white: {
        color: Colors.white,
    },
    programs: {
        margin: '0 0 2rem',
        padding: '1rem'
    },


}));

const resultsStyles = makeStyles(theme => ({
    resultsHeader: {
        display: 'flex',
        justifyContent: 'center',
        margin: '2rem 0',

    },
    results: {
        background: Colors.Accent2,
        padding: '1rem ',
        borderRadius: '300px  ',
        boxShadow: Colors.shadow,
        color: 'white',
        fontSize: '1rem',
        fontWeight: '500',
        minHeight: '90%',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        }

    },
    estimate: {
        display: 'flex',
        justifyContent: 'center',
        margin: '-1.5rem 0 0rem',
        fontSize: '2rem',
        fontWeight: 'bold',
        color: Colors.darkAccent1,
        '&::before': {
            content: '"$"',
        },
        '&::after': {
            content: '"/month"',
            fontSize: '1rem',
            fontWeight: 'normal',
            padding: '.5rem 0 0 0',
        }
    },

}));

const CalcSlider = withStyles(theme => ({
    root: {
        color: Colors.white,
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: Colors.darkAccent1,
        border: '2px solid currentColor',
        marginTop: -4,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 18,
        borderRadius: 4,
    },
    rail: {
        height: 18,
        borderRadius: 4,
    },
    marked: {
        marginBottom: '0'
    },
    mark: {
        backgroundColor: '#bfbfbf',

        height: 25,
        width: 2,
        marginTop: -3,
    },
    markLabel: {
        color: 'white',
        [theme.breakpoints.up('xl')]: {
            padding: '.5rem'
        },
        [theme.breakpoints.down('lg')]: {
            padding: '.5rem'
        },
        [theme.breakpoints.down('md')]: {
            padding: '1.25rem'
        }
    }
}))(Slider);

const CheckBoxLabel = withStyles({
    label: { color: Colors.white }
})(props => <FormControlLabel {...props} />);

const CustomCheckBox = withStyles({
    root: {
        color: Colors.white,
        '&$checked': {
            color: Colors.white,
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

export default function Calculator() {
    const classes = useStyles();
    const xsOnly = useMediaQuery(theme => theme.breakpoints.only('xs'));
    //const mdOnly = useMediaQuery(theme => theme.breakpoints.only('md'));
    const [amount, setAmount] = React.useState(250000);
    const [downpayment, setDownPayment] = React.useState(50000);
    const [rate, setRate] = React.useState(3);
    const [term, setTerm] = React.useState(30);
    const [hoa, setHoa] = React.useState(0);
    const [usda, setUsda] = React.useState(false);
    const [fha, setFha] = React.useState(false);
    const [va, setVa] = React.useState(false);
    const [con, setCon] = React.useState(true);
    const [refi, setRefi] = React.useState(false);
    const [vaDisabled, setVaDisabled] = React.useState(false);
    const [vaFirst, setVaFirst] = React.useState(false);
    const [tax, setTax] = React.useState(.011);
    const [isOpen, setIsOpen] = React.useState(false);
    const [snackParams, setSnackParams] = React.useState({ message: '', variant: 'info' });
    const [mLabel, setLabel] = React.useState(null);
    false && setTax(.011);
    if (amount > 0 && downpayment < 1) {
        !refi && setDownPayment(1)
    }
    const ltvCon = .97;
    const ltvFha = 0.965;

    const propertyInsurance = .0035;

    let adjustedAmount = 0;

    const numberWithCommas = (x) => {
        return `$${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    const marksFunc = () => {
        let min = !!fha ? 3.5 :
            !!con ? 3 : 0;
        let max = Math.round(downpayment / amount * 100) === 4 ? 3.5 : Math.round(downpayment / amount * 100);

        let marks = [];

        if (min && max > 10) {
            marks.push({
                value: min,
                label: `%${min}`
            });
        }
        if (max) {
            marks.push({
                value: max,
                label: `%${max}`
            });
        }

        return marks.length > 0 ? marks : []

    };
    const marks = marksFunc();
    const calcParamsMain = [
        ['amount-slider', 'Purchase Price', amount, (event, value) => { setAmount(value) }, 10000, 50000, 1000000, (event) => { setAmount(event.target.value === '' ? '' : Number(event.target.value)) }, <MonetizationOnIcon style={{ color: Colors.lightAccent1 }} />, []],
        ['down-slider', 'Down Payment', downpayment, (event, value) => { setDownPayment(value / 100 * amount) }, .5, 0, 100, (event) => { setDownPayment(event.target.value === '' ? '' : Number(event.target.value)) }, <AttachMoneyIcon style={{ color: Colors.lightAccent1 }} />, marks],
    ];
    const calcParams = [
        ['rate-slider', 'Interest', rate, (event, value) => { setRate(value) }, .125, 2, 6, (event) => { setRate(event.target.value === '' ? '' : Number(event.target.value)) }, <EmojiSymbolsIcon viewBox="12 11 12 12" style={{ color: Colors.lightAccent1 }} />, []],
        ['term-slider', 'Term', term, (event, value) => { setTerm(value) }, 5, 10, 40, (event) => { setTerm(event.target.value === '' ? '' : Number(event.target.value)) }, <DateRangeIcon style={{ color: Colors.lightAccent1 }} />, []],
        ['hoa-slider', 'HOA', hoa, (event, value) => { setHoa(value) }, 1, 0, 500, (event) => { setHoa(event.target.value === '' ? '' : Number(event.target.value)) }, <HomeIcon style={{ color: Colors.lightAccent1 }} />, []],
    ];

    const calcTypes = [
        [con, 'con', 'Conventional Loan'],
        [usda, 'usda', 'USDA Loan'],
        [fha, 'fha', 'FHA Loan'],
        [va, 'va', 'VA Loan'],
        [refi, 'refi', 'Refinancing']

    ];

    const vaOptions = [
        [vaDisabled, 'disabled', 'Disabled Veteran?'],
        [vaFirst, 'first', 'First VA Loan?'],
    ];

    const handleTypeChange = name => event => {
        if (name !== 'refi') {
            switch (event.target.checked) {
                case true:
                    name === 'usda' ? setUsda(true) : setUsda(false)
                    name === 'con' ? setCon(true) : setCon(false)
                    name === 'fha' ? setFha(true) : setFha(false)
                    name === 'va' ? setVa(true) : setVa(false)
                    break;
                case false:
                    name === 'usda' ? setUsda(false) : setUsda(false)
                    name === 'fha' ? setFha(false) : setFha(false)
                    name === 'va' ? setVa(false) : setVa(false)
                    setCon(true)
                    break;

                default:
                    break;
            }
        }
        if (name === 'refi') {
            switch (event.target.checked) {
                case true:
                    setDownPayment(0)
                    setRefi(true)
                    break;
                case false:
                    setDownPayment(.04 * amount)
                    setRefi(false)
                    break;
                default:
                    break;
            }
        }

    }

    const handleVaOptions = name => event => {
        setVaDisabled('disabled' !== name ? false : event.target.checked);
        setVaFirst('first' !== name ? false : event.target.checked);
    }

    const calcFunction = () => {
        if (!amount) {
            return null;
        }


        if (downpayment > amount && !refi) {
            setDownPayment(.2 * amount);
            setSnackParams({ message: 'Down payment cannot exceed the purchase price.', variant: 'warning' })
            setIsOpen(true);
        }

        adjustedAmount = amount - downpayment;

        if (fha === true) {
            //Funding Fee added to principal after downpayment
            adjustedAmount = 1.0175 * adjustedAmount;
            //Check if loan to value ratio is in range
            if ((1 - downpayment / amount) > ltvFha && !refi) {
                //ifnot set to minimum required downpayment
                setDownPayment(Math.round((1 - ltvFha) * amount));
                setSnackParams({ message: 'The FHA Loan program requires a down payment of 3.5%', variant: 'warning' })
                setIsOpen(true);
            }
        }

        if (con === true) {
            if ((1 - downpayment / amount) > ltvCon && !refi) {
                setDownPayment(((1 - ltvCon) * amount).toFixed(2));
                setSnackParams({ message: 'Conventional Loan programs require a down payment of 3%', variant: 'warning' })
                setIsOpen(true);
            }
        }
        if (usda === true) {

            adjustedAmount = (1.005 * adjustedAmount).toFixed(2);
        }
        if (va === true) {
            let vaHistory = vaDisabled !== true && vaFirst !== true ? 'other' :
                vaDisabled === true ? 'disability' :
                    vaFirst === true ? 'first' : 'other';

            switch (vaHistory) {
                case 'disablity':
                    break;
                case 'first':
                    adjustedAmount = (1.024 * adjustedAmount);
                    break;
                case 'other':
                    adjustedAmount = (1.036 * adjustedAmount);
                    break;
                default:
                    break;

            }
        }

        let totalAmount = adjustedAmount;
        let percentAnnualInterestRate = rate / 1200;
        let months = term * 12;
        let answer = (percentAnnualInterestRate + (percentAnnualInterestRate / (Math.pow((1 + percentAnnualInterestRate), months) - 1))) * totalAmount;
        let taxes = taxAdder();
        return {
            _answer: answer.toFixed(2),
            _total: totalPayment(answer, taxes.taxes),
            _taxa: taxes.taxes,
            _pri: taxes.pi,
            _tax: taxes.tax,
            _mmi: taxes.mmi,
            _hoa: hoa

        };
    }

    const taxAdder = () => {
        let Tax = (tax * amount / 12),
            Insurance = (propertyInsurance * amount / 12),
            mmi = 0;
        if (fha === true) { mmi = (downpayment / amount) > .05 ? ((.008 * adjustedAmount / 12)) : ((.0085 * adjustedAmount / 12)); }
        if (usda === true) { mmi = (.0035 * adjustedAmount / 12) }
        let taxFees = Tax + Insurance + mmi;
        taxFees = !!(typeof hoa === 'number') ? taxFees + hoa : taxFees;
        let tfObject = {
            taxes: taxFees,
            mmi: mmi,
            tax: Tax,
            pi: Insurance,
        };
        return tfObject;
    }

    const totalPayment = (calcf, taxa) => {
        let sum = calcf + taxa;
        return sum.toFixed(2)
    }

    const CalcResults = () => {

        let results = calcFunction();
        let colors = [];
        let legend = [];
        let key = new Map();
        let data = !amount ? [] : Object.getOwnPropertyNames(results)
            .filter((result) => (result !== '_total' && result !== '_taxa') && results[result] > 0)
            .map((result, index) => {

                let o = {};
                let l = {};
                switch (result) {
                    case '_answer':
                        o.y = results._answer / results._total;
                        o.label = `$${results._answer}`;
                        l.name = `Principal and Interest`;
                        l.symbol = { fill: Colors.lightAccent1 }
                        key.set(o.label, l.name)
                        colors.push(Colors.lightAccent1)
                        break;
                    case '_tax':
                        o.y = results._tax / results._total;
                        o.label = `$${results._tax.toFixed(2)}`;
                        l.name = `Tax and Impound`;
                        l.symbol = { fill: "tomato" }
                        key.set(o.label, l.name)
                        colors.push("tomato")
                        break;
                    case '_pri':
                        o.y = results._pri / results._total;
                        o.label = `$${results._pri.toFixed(2)}`;
                        l.name = `Property Insurance`;
                        l.symbol = { fill: "gold" }
                        key.set(o.label, l.name)
                        colors.push("gold")
                        break;
                    case '_mmi':
                        o.y = results._mmi / results._total;
                        o.label = `$${results._mmi.toFixed(2)}`;
                        l.name = `Monthy Mortgage Insurance`;
                        l.symbol = { fill: "lightblue" }
                        key.set(o.label, l.name)
                        colors.push("lightblue")
                        break;
                    case '_hoa':
                        o.y = results._hoa / results._total;
                        o.label = `$${results._hoa.toFixed(2)}`;
                        l.name = `HOA`;
                        l.symbol = { fill: "palegreen" }
                        key.set(o.label, l.name)
                        colors.push("palegreen")
                        break;
                    default:
                        break;
                }
                legend.push(l)
                return o;

            });



        const rclasses = resultsStyles();
        return (

            <Grid container direction='column' justify='center' align="center" wrap='nowrap' className={rclasses.results} >
                {/* <Grid item>
                    <p className={rclasses.resultsHeader}>Your Estimated Monthly Payment</p>
                    <p className={rclasses.estimate}>{results._total}</p>
                </Grid> */}

                <Grid item>
                    {!!amount && <svg width={xsOnly ? 280 : 600} height={xsOnly ? 300 : 320}>
                        <VictoryPie
                            width={xsOnly ? 280 : 320}
                            height={xsOnly ? 280 : 320}
                            standalone={false}
                            style={{ labels: { fill: 'white', fontSize: 20, }, }}
                            padAngle={2}
                            labelComponent={<VictoryLabel padding='15' />}
                            radius={xsOnly ? 130 : 160}
                            theme={VictoryTheme.material}
                            innerRadius={70}
                            cornerRadius={4}
                            labelRadius={({ innerRadius }) => (innerRadius + (xsOnly ? 5 : 20))}
                            labelPosition="centroid"
                            colorScale={colors}
                            data={data}
                            events={[{
                                target: "data",
                                eventHandlers: {
                                    onClick: () => {
                                        return [
                                            {
                                                target: "labels",
                                                mutation: ({ text }) => {
                                                    let newText = key.get(text);
                                                    setLabel(newText);
                                                }
                                            }
                                        ];
                                    }
                                }
                            }]}


                        />
                        {xsOnly && <VictoryLabel
                            standalone={false}
                            textAnchor="middle"
                            style={{ fontSize: 25, fontFamily: "Roboto", fill: Colors.white }}
                            x={140} y={285}
                            text={mLabel}
                        />}

                        <circle cx={xsOnly ? 140 : 160} cy={xsOnly ? 140 : 160} r={xsOnly ? 60 : 65} fill="#ffffff" />

                        <VictoryLabel
                            standalone={false}
                            textAnchor="middle"
                            style={{ fontSize: 25, fontFamily: "Roboto", fill: Colors.darkAccent1 }}
                            x={xsOnly ? 140 : 160} y={xsOnly ? 120 : 145}
                            text={numberWithCommas(results._total)}
                        />
                        <line x1={xsOnly ? 140 - 47 : 160 - 53} y1={xsOnly ? 140 : 160} x2={xsOnly ? 140 + 47 : 160 + 53} y2={xsOnly ? 140 : 160} style={{ stroke: Colors.Accent2, strokeWidth: 3 }} />
                        <VictoryLabel
                            standalone={false}
                            textAnchor="middle"
                            style={{ fontSize: 15, fontFamily: "Roboto", fill: Colors.Accent2 }}
                            x={xsOnly ? 140 : 160} y={xsOnly ? 155 : 175}
                            text="per month"
                        />
                        {!xsOnly && <VictoryLegend x={330} y={50}
                            width={700}
                            height={100}
                            standalone={false}
                            padding={{ top: 20, bottom: 60 }}
                            orientation="vertical"
                            style={{ labels: { fill: 'white', fontSize: 20 } }}
                            data={legend}
                        />}
                    </svg>}


                </Grid>


            </Grid>

        );
    }
    const snackState = (state) => setIsOpen(state);

    return (
        <div className={classes.wrapper}>
            <h2 style={{ margin: '0 0 2rem' }}>Mortgage Calculator</h2>
            <Grid container direction='row' justify='center' align='center' className={classes.calcContainer} >

                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                    <Grid container justify='center' >
                        {/*-----------------------------Start Check Boxes-----------------------------------*/}
                        <Grid container justify='center' className={classes.items}>
                            <FormGroup row className={classes.programs}>
                                {calcTypes.map((param, index) => (
                                    <CheckBoxLabel key={index}
                                        control={
                                            <CustomCheckBox
                                                checked={param[0]}
                                                onChange={handleTypeChange(param[1])}
                                                value={param[1]}
                                            />
                                        }
                                        label={param[2]}
                                    />
                                ))}
                            </FormGroup>
                            {!!va && <FormGroup row className={classes.vaOptions}>
                                {vaOptions.map((param, index) => (
                                    <FormControlLabel key={index}
                                        control={
                                            <Checkbox
                                                checked={param[0]}
                                                onChange={handleVaOptions(param[1])}
                                                value={param[1]}
                                                color='default'
                                            />
                                        }
                                        label={param[2]}
                                    />
                                ))}
                            </FormGroup>}
                        </Grid>
                        {/*-----------------------------End Check Boxes-----------------------------------*/}

                        {/*-----------------------------Start main Sliders-----------------------------------*/}
                        <Grid container spacing={4} className={classes.items}>
                            {!refi && calcParamsMain.map((param, index) => (
                                <Grid item key={index} xs={6} >
                                    <Typography id={param[0]} color='textSecondary' variant='h5' gutterBottom>
                                        {param[1]}
                                    </Typography>
                                    <Input
                                        color="secondary"
                                        startAdornment={<InputAdornment position="start" className={classes.white}><p style={{ color: Colors.white }}>{param[8]}</p></InputAdornment>}
                                        className={classes.white}
                                        classes={{ notchedOutline: classes.input }}
                                        value={param[2]}
                                        margin="dense"
                                        onChange={param[7]}
                                        inputProps={{
                                            step: param[4],
                                            min: param[5],
                                            max: param[6],
                                            type: 'number',
                                            'aria-labelledby': param[0],
                                        }}
                                    />

                                    <CalcSlider
                                        value={typeof param[2] !== 'number' ? 0 :
                                            param[1] === 'Down Payment' ? param[2] * 100 / amount : param[2]}
                                        onChange={param[3]}
                                        aria-labelledby={param[0]}
                                        valueLabelDisplay='off'
                                        marks={param[9]}
                                        getAriaValueText={numberWithCommas}
                                        step={param[1] === 'Down Payment' ? .5 : param[4]}
                                        min={param[1] === 'Down Payment' ? 0 : param[5]}
                                        max={param[1] === 'Down Payment' ? 100 : param[6]}
                                    />


                                </Grid>
                            ))}
                            {refi && calcParamsMain.filter(param => !param[0].includes('down')).map((param, index) => (
                                <Grid item key={index} xs={12} >
                                    <Typography id={param[0]} color='textSecondary' variant='h5' gutterBottom>
                                        Refinance Loan Amount
                                    </Typography>
                                    <Input
                                        color="secondary"
                                        startAdornment={<InputAdornment position="start" className={classes.white}><p style={{ color: Colors.white }}>{param[8]}</p></InputAdornment>}
                                        className={classes.white}
                                        classes={{ notchedOutline: classes.input }}
                                        value={param[2]}
                                        margin="dense"
                                        onChange={param[7]}
                                        inputProps={{
                                            step: param[4],
                                            min: param[5],
                                            max: param[6],
                                            type: 'number',
                                            'aria-labelledby': param[0],
                                        }}
                                    />

                                    <CalcSlider
                                        value={typeof param[2] !== 'number' ? 0 : param[2]}
                                        onChange={param[3]}
                                        aria-labelledby={param[0]}
                                        valueLabelDisplay='off'
                                        marks={param[9]}
                                        getAriaValueText={numberWithCommas}
                                        step={param[4]}
                                        min={param[5]}
                                        max={param[6]}
                                    />


                                </Grid>
                            ))}

                        </Grid>

                        {/*-----------------------------End main Sliders-----------------------------------*/}

                        {/*-----------------------------Start Secondary Sliders-----------------------------------*/}
                        <Grid container spacing={4} className={classes.items} >
                            {calcParams.map((param, index) => (
                                <Grid item key={index} xs={4}>
                                    <Typography id={param[0]} color='textSecondary' variant='h5' gutterBottom>
                                        {param[1]}
                                    </Typography>
                                    <Input
                                        color="secondary"
                                        startAdornment={<InputAdornment position="start" className={classes.white}><p style={{ color: Colors.white }}>{param[8]}</p></InputAdornment>}
                                        className={classes.white}
                                        classes={{ notchedOutline: classes.input }}
                                        value={param[2]}
                                        margin="dense"
                                        onChange={param[7]}
                                        inputProps={{
                                            step: param[4],
                                            min: param[5],
                                            max: param[6],
                                            type: 'number',
                                            'aria-labelledby': param[0],
                                        }}
                                    />

                                    <CalcSlider
                                        value={typeof param[2] === 'number' ? param[2] : 0}
                                        onChange={param[3]}
                                        aria-labelledby={param[0]}
                                        valueLabelDisplay='off'
                                        getAriaValueText={numberWithCommas}
                                        step={param[4]}
                                        min={param[5]}
                                        max={param[6]}
                                    />


                                </Grid>
                            ))}

                        </Grid>
                        {/*-----------------------------End Secondary Sliders-----------------------------------*/}
                    </Grid>

                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                    <CalcResults />
                </Grid>

            </Grid >
            <CustomizedSnackbars
                variant={snackParams.variant || 'warning'}
                message={snackParams.message}
                isopen={isOpen}
                snackState={snackState}
            />
        </div>
    )


}



