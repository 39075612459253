import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../components/colors';
import { useStore } from '../components/useFormState';

const useStyles = makeStyles(theme => ({


    root: {
        margin: '3rem',
        width: '100%'
    },
    field: {
        margin: '0 0 1rem'
    },
    inputRoot: {
        color: Colors.white,
        background: Colors.Accent2,
        fontWeight: 'bold',
        boxShadow: Colors.shadow,
        "&focus": {
            color: Colors.darkAccent1
        }
    },
    inputRootFocused: {
        color: Colors.Accent2,

    },

}));


export default function FormMortgage() {

    const classes = useStyles();

    const { state, dispatch } = useStore();
    const pickLists = (options) => {
        return options.map(option => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))
    }
    const types = [
        { value: 'Purchase', label: 'Purchase' },
        { value: 'Refinance', label: 'Refinance' },
    ];

    const programs = [
        { value: 'Conventional', label: 'Conventional' },
        { value: 'VA', label: 'VA Loan' },
        { value: 'USDA', label: 'USDA Loan' },
        { value: 'FHA', label: 'FHA Loan' },
        { value: 'Construction', label: 'Construction Loan' },
        { value: 'Alt. Income', label: 'Alternative Income Loan' },
        { value: 'Manufactured', label: 'Manufactured Home Loan' },
        { value: 'Commercial', label: 'Commercial Loan' },
        { value: 'Hard Money', label: 'Hard Money Loan' },
        { value: 'Renovation', label: 'Renovation Loan' },
    ];
    const terms = [
        { value: '30 Year', label: '30 Year' },
        { value: '25 Year', label: '25 Year' },
        { value: '20 Year', label: '20 Year' },
        { value: '15 Year', label: '15 Year' },
        { value: '10 Year', label: '10 Year' },
    ];
    const refiStatus = [
        { value: `Cash Out Equity`, label: `Cash Out Equity` },
        { value: `Lower Payment`, label: `Lower Payment` },
        { value: `Pay Off Faster`, label: `Pay Off Faster` },
    ];
    const purStatus = [
        { value: `I have found a home and I'm ready to make an offer.`, label: `I have found a home and I'm ready to make an offer.` },
        { value: `I am actively searching for my next home.`, label: `I am actively searching for my next home.` },
        { value: `I'm not ready, still learning about the process.`, label: `I'm not ready, still learning about the process.` },
    ];

    const finFields = [
        {
            id: "applicant-type",
            label: "Loan Type",
            placeholder: "Home Purchase or Refinance",
            name: 'loanType',
            state: state.loanType,
            type: 'text', select: true,
            options: pickLists(types)
        },
        {
            id: "applicant-preferred-program",
            label: "Mortgage Program",
            placeholder: "Conventional",
            name: 'mortgageProgram',
            state: state.mortgageProgram,
            type: 'text',
            select: true,
            options: pickLists(programs)
        },
        {
            id: "applicant-loan-ammount",
            label: "Loan Amount",
            placeholder: "$300,000",
            name: 'loanAmount',
            state: state.loanAmount,
            type: 'text',
        },
        {
            id: "applicant-down-payment",
            label: "Down Payment",
            placeholder: "$30,000",
            name: 'downpayment',
            state: state.downpayment,
            type: 'text',
            disabled: state.loanType.value === 'Refinance' ? true : false
        },
        {
            id: "applicant-term",
            label: "Mortgage Term",
            placeholder: "30 Year",
            name: 'term',
            state: state.term,
            type: 'text',
            select: true,
            options: pickLists(terms)
        },
        {
            id: "applicant-status",
            label: "Status",
            placeholder: "Pre-Approved",
            name: 'status',
            select: true,
            state: state.status,
            type: 'text',
            options: state.loanType.value === 'Refinance' ? pickLists(refiStatus) : pickLists(purStatus)
        },

    ];




    return (


        <form className={classes.root} noValidate  >
            {finFields.map((field, index) => (
                <TextField
                    variant="filled"
                    color="primary"
                    fullWidth={true}
                    className={classes.field}
                    name={field.name}
                    id={field.id}
                    key={index}
                    disabled={field.disabled}
                    type={field.type}
                    label={field.label}
                    select={field.select}
                    required={field.state.required}
                    value={field.state.value}
                    error={field.state.error}
                    helperText={field.state.errorMsg}
                    placeholder={field.placeholder}
                    InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot, focused: classes.inputRootFocused } }}
                    onChange={(e) => { dispatch({ type: "handleChange", name: e.target.name, value: e.target.value }) }}
                    onBlur={(e) => { dispatch({ type: "handleBlur", name: e.target.name, value: e.target.value }) }}
                >
                    {field.options}
                </TextField>


            ))}
        </form>

    )
}