import React from 'react'
import Colors from '../components/colors'

const SvgComponent = props => (
    <svg viewBox="0 0 56.11 62.24" {...props}>
        <defs>
            <style>
                {`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}
            </style>
        </defs>
        <title>{'Asset 8'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g
                id="prefix__Real_Estate_Homescapes_Icons"
                data-name="Real Estate &amp; Homescapes Icons"
            >
                <g id="prefix__Worker">
                    <path
                        className="prefix__cls-1"
                        d="M10.39 54.59h35.74V61c-1.93.25-3.88.57-5.84.73a141.08 141.08 0 01-26.95-.25c-2.95-.31-2.95-.31-2.95-3.31z"
                    />
                    <path
                        className="prefix__cls-2"
                        d="M18.43 37.4c5.94 7.64 14.06 6.82 19.07.17 2.28.4 2.29.4 2.29 2.65v8.83c0 1-.25 1.44-1.36 1.43q-10.46-.06-20.9 0c-.93 0-1.31-.26-1.29-1.25 0-3.33.06-6.66 0-10 0-1.7 1.52-1.15 2.19-1.83zM12.1 40.34v9.35c0 .26-.56.7-.89.74-3.66.42-4.84 1.69-4.86 5.35v4.67c-2.1-.73-4-1.36-5.86-2.08-.24-.09-.46-.63-.45-.95.35-7.67 3.56-14.57 11.76-17.15a2.39 2.39 0 01.3.07zM44.09 40.16c3.85 1.43 7.15 3.32 9.15 7a22.48 22.48 0 012.87 10 1.35 1.35 0 01-1 1.56c-1.51.49-3 1-4.77 1.59 0-1.67-.06-3.09 0-4.5.18-3.45-1.94-5.53-5-5.28-.95.07-1.38-.21-1.35-1.3.08-2.81 0-5.62 0-8.43a4.33 4.33 0 01.1-.64z"
                    />
                    <path
                        className="prefix__cls-1"
                        d="M42.81 13.38a1.57 1.57 0 00-1.28-.64l-.09-.15a3.24 3.24 0 01-.25-.88 10.42 10.42 0 00-.68-2.58 11.68 11.68 0 00-3.42-4.59 1.8 1.8 0 00-.63-.5c-.39-.22-.79-.43-1.15-.6V6.3c0 .61-.13 1.17-1 1.19a.89.89 0 01-1-.89 1.14 1.14 0 010-.26c0-1-.07-1.89 0-2.83a.41.41 0 000-.11A1.3 1.3 0 0032 1.8l-.38-.08h-.16c-.6-.14-1.12-.4-1.13-1.2 0-.15-.5-.41-.78-.43h-.32a21.81 21.81 0 00-2.8 0c-.28 0-.77.28-.77.44 0 .85-.59 1.1-1.24 1.24l-.26.05h-.15c-1 .19-1.28.66-1.25 1.44a1.28 1.28 0 000 .19 28.6 28.6 0 01-.06 2.95 2.44 2.44 0 010 .27.89.89 0 01-1 .89c-.86 0-1-.56-1-1.18 0-.88 0-1.77-.05-2.8v-.15h-.05a9.23 9.23 0 00-1 .56 12 12 0 00-4.08 5.16 11.33 11.33 0 00-.68 2.68 3.16 3.16 0 01-.16.72 1.11 1.11 0 01-.07.16h-.07A1.44 1.44 0 0013.05 14a.49.49 0 000 .12v.11a1 1 0 00.1.42 1.25 1.25 0 001.09.67h3.08a33.72 33.72 0 003.84 0h20.75a1.21 1.21 0 001-.66 1 1 0 00.1-.42v-.11a1.2 1.2 0 00-.2-.75z"
                    />
                    <path
                        className="prefix__cls-2"
                        d="M40.73 22.83a8.53 8.53 0 00.88-4.83H14.55a8.67 8.67 0 00.86 4.94c-2 .75-2.48 2.17-1.26 3.43a17 17 0 002 1.47 10.87 10.87 0 011.5 1.26c1.6 1.78 2.87 3.8 4.77 5.35a8.85 8.85 0 0011.31 0c1.84-1.46 3.07-3.39 4.63-5.08a7.68 7.68 0 011.94-1.68c2.79-1.48 3.14-3.62.43-4.86zm-3.59 5.87c-1.71 2.74-4.94 7.33-9.93 6.69-3-.39-5.08-2.46-6.63-4.36a18.48 18.48 0 01-2.18-3.2c-.21-.41-1.45-4.31-1.59-4.33 0 0 9.27 1.12 15.77-3.71.35-.26.7-.55 1-.85a4 4 0 00.31.85 6.84 6.84 0 005.43 3.82c.36.06-2 4.8-2.18 5.09z"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default SvgComponent
