import React, { Component } from 'react'

export class login extends Component {
    render() {
        return (
            <div>
                <h1>login</h1>
            </div>
        )
    }
}

export default login