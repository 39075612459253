import React from 'react';
import Colors from '../components/colors';

const SvgComponent = props => (
    <svg viewBox="0 0 123.93 110.69" {...props}>
        <defs>
            <style>{`.prefix__cls-2{fill:${Colors.dark}}.prefix__cls-1{fill:${Colors.Accent2}}`}</style>
        </defs>
        <title>{'Asset 4'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g id="prefix__Layer_1-2" data-name="Layer 1">
                <g id="prefix__Cost_Minimization" data-name="Cost Minimization">
                    <path
                        className="prefix__cls-1"
                        d="M45.94 107.41a16.84 16.84 0 004.64-22.13 17.52 17.52 0 00-1.14-1.71A4.45 4.45 0 0149 78.2c6.73-12.42 12.26-23.11 17.6-33.44C71.66 35 76.88 24.88 83.11 13.27l.06-.1c1.69-3.19 3.36-6.36 4.91-9.5.13-.25.25-.51.37-.76a2.46 2.46 0 000-1.81 1.36 1.36 0 00-.65-.83 2.54 2.54 0 00-.91-.27 2 2 0 00-1.28.25L85 .63c-3.6 2.1-7.12 4.37-10.79 6.6-.31.24-.61.49-.91.74-.54.46-1.08.92-1.62 1.4A37.74 37.74 0 0065.19 17l-5.89 8.75-10.91 16.18L47 44l-6.49 9.6a20.44 20.44 0 00-3.6 11.74 54 54 0 01-.87 8.29c-.34 2-1.47 3.14-3.8 3.66a17 17 0 00-11 25l.07.11a16.88 16.88 0 0011.13 8 17.07 17.07 0 0013.5-2.99zM42.6 64.74a5.34 5.34 0 011.88-7.22 5.3 5.3 0 015.42 9.1 5.32 5.32 0 01-7.3-1.88zm-9.44 39.45a10.65 10.65 0 01-6.51-5v-.07a10.77 10.77 0 0118.6-10.76v.09a10.79 10.79 0 01-12.14 15.74zM37 51.18l1.92-2.85-1.76-.61a4.47 4.47 0 01-3.34-4.24 19.31 19.31 0 00-.32-2 16.84 16.84 0 00-18.4-13.17 17.07 17.07 0 00-11.93 7A16.9 16.9 0 00.38 48.72v.13a16.78 16.78 0 009.73 11.77 16.84 16.84 0 0015.3-.84c2.06-1.2 3.64-1.16 5.27.12.71.55 1.45 1.14 2.21 1.76A24.33 24.33 0 0137 51.18zm-17.71 4.45a10.66 10.66 0 01-12.82-8.16v-.07a10.76 10.76 0 0121-4.7v.1a10.67 10.67 0 01-8.18 12.83z"
                    />
                    <path
                        className="prefix__cls-1"
                        d="M123.2 78.16l-14-33.58-13.49-33.82a9.87 9.87 0 00-2.84-3.88c-1 2-2.06 4.07-3.16 6.16v.15l13.74 33.69 13.73 33.69a3.16 3.16 0 01-1.88 4.75l-18.67 8-18.89 7.36a3.17 3.17 0 01-4.69-2.08L60.77 68.34C59.55 70.66 58.29 73 57 75.47L67.14 101a10.23 10.23 0 0013.06 5.61l37.59-15.31a10.24 10.24 0 005.41-13.14zM45.07 20.47a10.24 10.24 0 00-5.42 13.15l2.85 6.81 1.15-1.71 3.14-4.66-1.17-2.87a3.17 3.17 0 011.89-4.75l6.21-2.65.84-1.24 5.49-8.18z"
                    />
                    <path
                        className="prefix__cls-2"
                        d="M107.7 70L99 48.72l-8.66-21.29A43.56 43.56 0 0183 25.72c-4.15 7.86-7.88 15.07-11.28 21.67l-.11.19c-2.61 4.93-5.16 9.92-7.9 15.17l.12.3 8.67 21.27s11.1 1.41 14.8 6.2l14.15-5.77c-.7-6.01 6.25-14.75 6.25-14.75zm-22.22-4.18a11 11 0 01-12.9-4.51 10.56 10.56 0 01-.79-1.49 9.39 9.39 0 01-.47-1.61A11 11 0 0177.37 46a11 11 0 0112.91 4.51 10.49 10.49 0 01.79 1.49 10.87 10.87 0 01.47 1.61 11 11 0 01-6.06 12.21z"

                    />
                </g>
            </g>
        </g>
    </svg>
)

export default SvgComponent
