import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles, TextField, Button, MenuItem} from '@material-ui/core';
import source from '../templates/template';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '10vh auto',
    width: '80%'
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  accent: {
    fontWeight: 'bold'
  }
}));
const options = [
  {label: 'Admin (Testing)', value:'5cdaf41b-f218-43d5-9d9b-5079ba73ebd3'},
  {label: 'Valid', value:'c9b9805f-92aa-4415-adae-31ccfa332b48'}
  ];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const api = async ({url, method, body}) => {
  return await fetch(url,{
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': 'application/json',
    },
    body: body // body data type must match "Content-Type" header
  });
};

export default function SendRates() {
  const classes = useStyles();
  const baseUrl = 'https://us-central1-reddinghomeloan.cloudfunctions.net/sendgrid';
  const now = new Date(Date.now());
  const [name, setName] = useState(`Rates for ${months[now.getUTCMonth()]} ${now.getUTCDate()}`);
  const [subject, setSubject] = useState(`Rate Sheet for the week of ${months[now.getUTCMonth()]} ${now.getUTCDate()}`);
  const [body, setBody] = useState('');
  const [json, setJSON] = useState('');
  const [quote, setQuote] = useState('');
  const [list_id, setId] = useState("5cdaf41b-f218-43d5-9d9b-5079ba73ebd3");
  const handleList = (e) => {
    setId(e.target.value);
  };
  const send = async () => {
    const rates = JSON.parse(json);
    const html = source({
        quote: quote,
        body: body,
        con: rates[0].rate,
        fha: rates[1].rate,
        va: rates[2].rate,
        usda: rates[3].rate
      });
    console.log(html);
    const createBody = JSON.stringify({
      json: rates,
      createBody: {
        "name": name,
        "send_to": {
          "list_ids": [list_id]
        },
        "email_config": {
          "subject": subject,
          "html_content": html,
          "generate_plain_content": true,
          "editor": "design",
          "suppression_group_id": -1,
          "sender_id": 617680
        }
      }
    });
    console.log(createBody);
    const createResp = await api({
      url: baseUrl,
      method: 'POST',
      body: createBody,
    });
    alert(JSON.stringify(createResp.body, null, 3));
  };

  return (
    <Grid container className={classes.root} align='center' direction='column' wrap="nowrap">
        <Typography variant="h3">Send Rates</Typography>
      <Grid container item className={classes.form} justify="center" direction='column'>
        <Grid item>
          <TextField
            color="primary"
            placeholder="Email Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            color="primary"
            placeholder="Subject"
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            color="primary"
            placeholder="Body"
            value={body}
            onChange={e => setBody(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            color="primary"
            placeholder="Quote"
            value={quote}
            onChange={e => setQuote(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            color="primary"
            placeholder={`Rates JSON\n\n\n`}
            multiline
            rowsMax={4}
            value={json}
            onChange={e => setJSON(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            color="primary"
            placeholder="Recipient List"
            select
            value={list_id}
            onChange={handleList}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container item justify="center">
        <Grid item>
          <Button onClick={send}>Send</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
