import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import NavMenu from '../components/menu';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
//images
import logo from '../images/logo.png';
import logo2 from '../images/logo@2x.png';
import logo3 from '../images/logo@3x.png';
import Colors from './colors';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  show: {
    transition: 'background .5s ease-out',
    background: props => props.headerBG,
    boxShadow: props => props.boxShadow,
    zIndex: '99999999',




  },
  hide: {
    transition: 'background 1s ease-in',
    background: props => props.headerBG,
    boxShadow: props => props.boxShadow,
  },
  applogo: {
    position: 'relative',
    padding: '10px 35px 15px 5px',
    maxHeight: '5vh',
    minHeight: '30px',
    filter: 'drop-shadow(2px 5px 3px #000)',
    [theme.breakpoints.only('xs')]: {
      maxHeight: '0px'
    },
  },
  menuList: {
    color: Colors.dark,
  },
  navButton_noScroll: {
    color: Colors.dark,
    margin: 'auto 1vw',
    fontSize: '1rem',
    fontWeight: '700',
  },
  navButton_Scroll: {
    color: Colors.white,
    margin: 'auto 1vw',
    fontSize: '1rem',
    fontWeight: '700',
    filter: 'drop-shadow(2px 5px 3px #000)',
  },
  callUs: {
    color: Colors.white,
    border: 'solid',
    fontSize: '1.1em',
    filter: 'drop-shadow(2px 5px 3px #000)',
  }
}));




function Navbar() {
  const [width, setWidth] = React.useState(0);
  const [scrolled, setScrolled] = React.useState(false);
  const [lastscrolled, setLastScrolled] = React.useState(0);


  const measureImage = (e) => {

    let _width = e.target.offsetWidth || 100;

    setWidth(_width)
  }


  const bg = !!scrolled ? 'rgba(34, 34, 34, .7)' : `linear-gradient(134deg, ${Colors.Accent2} ${width - 30}px, transparent ${width - 27}px)`
  const bs = !!scrolled ? null : 'unset';
  const props = {
    headerBG: bg,
    boxShadow: bs
  }

  const classes = useStyles(props);


  const handleScroll = (e) => {
    const scroll = window.scrollY;
    if (lastscrolled === scroll) {

      return;
    }

    if (scroll !== 0) {
      setScrolled(true);
      setLastScrolled(scroll);

    }
    else {
      setScrolled(false);
      setLastScrolled(scroll);

    }
  }





  window.addEventListener('scroll', handleScroll);



  return (


    <AppBar position="fixed" className={!!scrolled ? classes.show : classes.hide}>
      <Toolbar className={classes.root}>
        <a href='/'>
          <img alt='rhl logo' id='logo' className={classes.applogo} onLoad={measureImage} src={logo} srcSet={`${logo} 300w, ${logo2} 768w, ${logo3} 1280w`} />
        </a>
        <Hidden smDown>
          <div className={classes.menuList}>
            <a href='tel: 1-530-941-0230' className={!!scrolled ? classes.navButton_Scroll : classes.navButton_noScroll}>(530) 941-0230</a>
            <Button component={Link} className={!!scrolled ? classes.navButton_Scroll : classes.navButton_noScroll} to='/purchase'>Purchase</Button>
            <Button component={Link} className={!!scrolled ? classes.navButton_Scroll : classes.navButton_noScroll} to='/refinance'>Refinance</Button>
            <Button component={Link} className={!!scrolled ? classes.navButton_Scroll : classes.navButton_noScroll} to='/rates'>Rates</Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <NavMenu scrolled={scrolled} />
        </Hidden>
      </Toolbar>
    </AppBar>

  );

}
Navbar.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Navbar);