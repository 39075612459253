import React from 'react';
import Colors from '../components/colors'
const SvgComponent = props => (
    <svg viewBox="0 0 124.47 96.53" {...props}>
        <defs>
            <style>
                {`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-3{fill:${Colors.Accent2}}`}
            </style>
        </defs>
        <title>{'Asset 3'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g id="prefix__Layer_1-2" data-name="Layer 1">
                <path
                    className="prefix__cls-1"
                    d="M124.14 27.1c-.9-2-1.93-4-2.78-6q-3.81-8.93-7.49-17.85C113.2 1.6 111.49.7 109.16.32a35.06 35.06 0 00-5.34-.32H20.65a34.89 34.89 0 00-5.34.34c-2.33.38-4 1.28-4.71 2.93q-3.69 8.95-7.49 17.87c-.85 2-1.88 4-2.78 6s-.06 3.34 2.46 4.43a11.08 11.08 0 002.86.88c6.57.89 13.17 1.15 19.48-.63a43.34 43.34 0 006-2.66c6.21 5.54 24.28 5.61 31.09-.14 6.82 5.75 24.88 5.68 31.09.14a43.88 43.88 0 006 2.66c6.32 1.78 12.92 1.52 19.49.63a11.2 11.2 0 002.86-.88c2.54-1.13 3.34-2.57 2.48-4.47zM26 7.5l-1.5 16.92a2.86 2.86 0 01-3 2.61L19.93 27a2.85 2.85 0 01-2.74-3.11l1.5-16.93a2.85 2.85 0 013-2.61l1.58.06A2.87 2.87 0 0126 7.5zm20 0l-1.5 16.92a2.86 2.86 0 01-3 2.61L40 27a2.85 2.85 0 01-2.74-3.11l1.47-16.96a2.87 2.87 0 013-2.61l1.58.06A2.87 2.87 0 0146 7.5zm19.58 16.64A2.87 2.87 0 0162.71 27h-1.59a2.86 2.86 0 01-2.87-2.86V7.19a2.86 2.86 0 012.87-2.87h1.59a2.87 2.87 0 012.87 2.87zM83.84 27h-1.58a2.87 2.87 0 01-3-2.61L77.8 7.5a2.86 2.86 0 012.75-3.12l1.58-.06a2.85 2.85 0 013 2.61l1.5 16.93A2.86 2.86 0 0183.84 27zm20 0l-1.58.06a2.85 2.85 0 01-3-2.61L97.85 7.5a2.86 2.86 0 012.74-3.12l1.59-.06a2.86 2.86 0 013 2.61l1.5 16.93a2.87 2.87 0 01-2.79 3.14zM56.93 89.08H7.2v7.45h111.41v-7.45H56.93z"
                />
                <g id="prefix__Storefront">
                    <path
                        className="prefix__cls-1"
                        d="M113.4 30.13c.2 0 .4.05.13 0s-.25 0-.38 0l-.93-.06c-.76 0-1.53-.05-2.29 0h-.6c-.15.07-.3.12-.45.18a1.8 1.8 0 01-.45.09 1.63 1.63 0 01-.09.19v33.4H16.12V30.44a2.17 2.17 0 010-.36v-.28H16a59.66 59.66 0 01-6.13 0v57.49h104.76V30.23c-.41-.01-.83-.05-1.23-.1z"
                    />
                    <path
                        className="prefix__cls-3"
                        d="M25.12 39.36h19.33v15.65H25.12zM52.57 39.36H71.9v15.65H52.57zM80.02 39.36h19.33v15.65H80.02z"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default SvgComponent
