import React from 'react';
import Helmet from 'react-helmet';
import Meta from '../components/seo';
import { useLocation } from 'react-router-dom';


export default function Head() {
    const { pathname } = useLocation();
    const page = pathname.slice(1)
    const seo = Meta[page] || {};
    return (
        <Helmet
            title={seo.title}
            meta={[
                {
                    name: "description",
                    property: "og:description",
                    content: seo.description
                },
                { property: "og:title", content: seo.title },
                { property: "og:url", content: seo.url },
                { property: "og:image", content: seo.image },
                { property: "og:image:type", content: "image/png" },
                { property: "twitter:image:src", content: seo.image },
                { property: "twitter:title", content: seo.title },
                { property: "twitter:description", content: seo.description }
            ]}
        />
    )
}


