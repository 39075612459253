const Colors = {
    white: 'white',
    dark: '#222',
    lightAccent1: '#ffa400',
    darkAccent1: '#ec6400',
    Accent2: '#164A84',
    owbg: '#f1f1f1',
    shadow: '3px 7px 9px 2px rgba(0, 0, 0, 0.4), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
} //'#ff6d02'

export default Colors;