import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import createTheme from '@material-ui/core/styles/createMuiTheme';
import { StylesProvider, ThemeProvider as MuiThemeProvider, createGenerateClassName } from '@material-ui/core/styles';


//components
import Navbar from './components/navbar';
import Footer from './components/footer';
import Head from './components/helmet';

//pages
import Home from './pages/home';
import rates from './pages/rates';
import apply from './pages/apply';
import login from './pages/login';
import contact from './pages/contact';
import purchase from './pages/purchase';
import refinance from './pages/refinance';
import privacy from './pages/privacy';
import sendRates from "./pages/sendRates";

import error from './pages/404'

import Colors from './components/colors'


//styles
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Accent2,
    },
    secondary: {
      main: Colors.darkAccent1,
    },
    text: {
      primary: Colors.dark,
      secondary: Colors.white
    }
  }
});


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}



/**
 * This is the list of the style rule name we use as drop in replacement for the built-in
 * pseudo classes (:checked, :disabled, :focused, etc.).
 *
 * Why do they exist in the first place?
 * These classes are used at a specificity of 2.
 * It allows them to override previously definied styles as well as
 * being untouched by simple user overrides.
 */
//var pseudoClasses = ['checked', 'disabled', 'error', 'focused', 'focusVisible', 'required', 'expanded', 'selected']; // Returns a function which generates unique class names based on counters.
// When new generator function is created, rule counter is reset.
// We need to reset the rule counter for SSR for each request.
//
// It's inspired by
// https://github.com/cssinjs/jss/blob/4e6a05dd3f7b6572fdd3ab216861d9e446c20331/src/utils/createGenerateClassName.js

// function createGenerateClassName() {
//   var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
//   var _options$disableGloba = options.disableGlobal,
//     disableGlobal = _options$disableGloba === void 0 ? false : _options$disableGloba,
//     _options$productionPr = options.productionPrefix,
//     productionPrefix = _options$productionPr === void 0 ? 'jss' : _options$productionPr,
//     _options$seed = options.seed,
//     seed = _options$seed === void 0 ? '' : _options$seed;
//   var seedPrefix = seed === '' ? '' : "".concat(seed, "-");
//   var ruleCounter = 0;
//   return function (rule, styleSheet) {
//     ruleCounter += 1;

//     if (process.env.NODE_ENV !== 'production') {
//       if (ruleCounter >= 1e10) {
//         console.warn(['Material-UI: you might have a memory leak.', 'The ruleCounter is not supposed to grow that much.'].join(''));
//       }
//     }

//     var name = styleSheet.options.name; // Is a global static MUI style?

//     if (name && name.indexOf('Mui') === 0 && !styleSheet.options.link && !disableGlobal) {
//       // We can use a shorthand class name, we never use the keys to style the components.
//       if (pseudoClasses.indexOf(rule.key) !== -1) {
//         return "Mui-".concat(rule.key);
//       }

//       var prefix = "".concat(seedPrefix).concat(name, "-").concat(`-${Math.random().toString(36).substring(2, 4)}-${Math.random().toString(36).substring(2, 4)}-`).concat(rule.key);



//       return "".concat(prefix, "-").concat(ruleCounter);
//     }

//     if (process.env.NODE_ENV === 'production') {
//       return "".concat(seedPrefix).concat(productionPrefix).concat(`-${Math.random().toString(36).substring(2, 4)}-${Math.random().toString(36).substring(2, 4)}-`).concat(ruleCounter);
//     }

//     var suffix = "".concat(rule.key, "-").concat(`${Math.random().toString(36).substring(2, 4)}-`).concat(ruleCounter); // Help with debuggability.

//     if (styleSheet.options.classNamePrefix) {
//       return "".concat(seedPrefix).concat(styleSheet.options.classNamePrefix, "-").concat(suffix);
//     }

//     return "".concat(seedPrefix).concat(suffix);
//   };
// }



const generateClassName = createGenerateClassName({
  productionPrefix: 'ReddingHomeLoans',
});


function App() {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <Head />
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/apply" component={apply} />
            <Route exact path="/contact" component={contact} />
            <Route exact path="/rates" component={rates} />
            <Route exact path="/login" component={login} />
            <Route exact path="/purchase" component={purchase} />
            <Route exact path="/refinance" component={refinance} />
            <Route exact path="/privacy" component={privacy} />
            <Route exact path="/admin/send-rates" component={sendRates} />
            <Route component={error} />
          </Switch>
          <Footer />
        </Router>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
