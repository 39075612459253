import React from 'react'
import Colors from '../components/colors'
const SvgComponent = props => (
  <svg viewBox="0 0 63.24 63.29" {...props}>
    <defs>
      <style>{`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}</style>
    </defs>
    <title>{'New Home'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g
        id="prefix__Real_Estate_Homescapes_Icons"
        data-name="Real Estate &amp; Homescapes Icons"
      >
        <g id="prefix__Home_Security" data-name="Home Security">
          <path className="prefix__cls-1" d="M11.63 44.15h-1.49" />
          <path className="prefix__cls-2" d="M10.14 44.13h3.73" />
          <path
            className="prefix__cls-2"
            d="M11.4 4h4.11a1.08 1.08 0 011.08 1v2.54c.49-.34.76-.5 1-.69l8-6.18a3 3 0 014 .15l11.52 8.85c3.11 2.39 6.22 4.79 9.33 7.17a2.36 2.36 0 011.17 2.39c-.39 1.94-2.88 2.65-4.68 1.3-2.67-2-5.3-4.06-8-6.1-3.49-2.68-7-5.34-10.45-8.06a1.32 1.32 0 00-2 0q-9.07 7.06-18.29 14.04a4.48 4.48 0 01-1.45.8 2.89 2.89 0 01-3.13-1 2.21 2.21 0 01.58-3C6 15.73 7.82 14.39 9.58 13a2 2 0 00.71-1.36c.06-2.06 0-4.13 0-6.57A1.08 1.08 0 0111.4 4z"
          />
          <path
            className="prefix__cls-1"
            d="M63.22 42.66a7.73 7.73 0 00-7.74-7.8h-7.25V24.58l-1.64-1.19L27.51 9.47h-.13L8.3 23.39l-1.65 1.19v19.55H5.36a1.37 1.37 0 00-1.06.47C3 46 1.61 47.32.25 48.66c-.33.3-.33.48 0 .81 1.42 1.39 2.78 2.81 4.2 4.17a1 1 0 00.73.31c2-.38 3.35.53 4.51 2 .48.64.53.59 1.11 0s1-1 1.55-1.51a1 1 0 011.72 0c.58.58 1.16 1.13 1.72 1.74.3.33.51.3.78 0 .58-.61 1.14-1.19 1.75-1.74s1-.59 1.62 0S21 55.46 21.56 56a6.51 6.51 0 00.53.48c.68-.69 1.34-1.32 2-1.95a1.59 1.59 0 011.29-.53H36c.68 0 1 .18 1.19.56a17.33 17.33 0 00.48 3.6 7.66 7.66 0 007.19 5.13h10.7A7.64 7.64 0 0061 60.94a7.7 7.7 0 002.15-5.38c.1-4.8.1-9 .07-12.9zm-28.5 7.91h-28a1.5 1.5 0 110-3h28a1.5 1.5 0 010 3zm3-10.57a17.34 17.34 0 00-.48 3.62.93.93 0 01-.91.53H10.14v-16.9a1.73 1.73 0 01.73-1.41l.43-.32 15.12-11a1.77 1.77 0 012 0l15.13 11 .44.32a1.74 1.74 0 01.71 1.41v7.62A7.71 7.71 0 0037.71 40zm22.85 15.06a5.53 5.53 0 01-5.51 5.51h-9.71a5.52 5.52 0 01-5.5-5.51V43.6a5.52 5.52 0 015.5-5.51h9.71a5.53 5.53 0 015.51 5.51z"
          />
          <path
            className="prefix__cls-2"
            d="M57.81 45v8.1a2.12 2.12 0 01-.58 1.54 2.33 2.33 0 01-2.68.79A2.28 2.28 0 0153 53.24v-8.4a2.15 2.15 0 011.21-2 2.39 2.39 0 013.6 2.16zM23.06 21.83h3v4H22v-3a1.07 1.07 0 011.06-1zM22 28.28h4v4h-3a1.08 1.08 0 01-1-1.05zM32.9 22.9v3h-4v-4h2.95a1.07 1.07 0 011.05 1zM28.87 28.28h4v2.95a1.08 1.08 0 01-1.08 1.08h-2.92z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
