import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Colors from './colors';

import StepConnector from '@material-ui/core/StepConnector';

import HomeIcon from '../images/newhome.js';
import ApplyIcon from '../images/apply.js';
import ConsultIcon from '../images/consult.js';
import EscrowIcon from '../images/escrow.js';
import ShopIcon from '../images/homeshop.js';



const useStyles = makeStyles(theme => ({
  background: {
    background: Colors.owbg
  },
  root: {
    backgroundColor: Colors.Accent2,
    borderRadius: '15px',
    margin: '1rem'
  },
  container: {
    boxShadow: '0 10px 30px rgba(51,51,51,.9)',
    backgroundColor: Colors.Accent2,
    borderRadius: '15px',
    margin: '1rem 1rem 5rem'
  },
  header: {
    color: `${Colors.Accent2} !important`,
    margin: '3rem 0',
    fontWeight: '900',

  },
  typeHeader: {
    fontSize: '2rem'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepContainer: {
    background: Colors.white,
    borderRadius: '10px',
    color: Colors.dark
  },
  label: {
    color: Colors.white,
    fontSize: '1.2rem',
  },
  activeLabel: {
    color: `${Colors.white} !important`,
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    stepContent: {
      maxWidth: '20vw',
      margin: '2vh'
    },
    carousel: {
      width: '30rem',
      margin: '2vh'
    },
  },
  [theme.breakpoints.down('md')]: {
    stepContent: {
      maxWidth: '95vw',
      margin: '2vh'
    },
    carousel: {
      display: 'none',
    }
  },
  carousel: {
    margin: '2rem',
  },
  icons: {
    fontSize: '30rem',
    borderRadius: '20px',
    width: '30rem',
    padding: '1.5rem',
    maxHeight: '24rem'
  }

}));

const useStepIconStyles = makeStyles({
  root: {
    color: Colors.white,
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: Colors.darkAccent1,
    transform: 'scale(2)',

  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
});




const Connector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  vertical: {
    margin: '0 4px 0 4px',
  },
  active: {
    color: Colors.dark,
  },

  line: {
    borderColor: Colors.white,
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function StepIcon(props) {
  const classes = useStepIconStyles();
  const { active } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={classes.circle} />
    </div>
  );
}







export default function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();


  const handleStep = step => () => {
    setActiveStep(step);
  };
  function getSteps() {
    return [
      'Apply for Pre-Approval', 'Schedule Consult', 'Shop for Home', 'Escrow', 'Close on Your New House'
    ];
  }
  function getStepContent(step) {
    switch (step) {
      case 0:
        return `Getting pre-approved is the first step in finding your new home. Many make the mistake of skipping this crucial step before 
        finding the home they want. This can lead to delays or worse when it is time to open escrow. Be smart, and be sure that you can 
        get qualified for the house you want to buy. Once you are pre-approved by Redding Home Loans, you can begin your home search with confidence. `;
      case 1:
        return `A good, old fashioned, in person meeting is our style. We want to provide 
        you our full attention so nothing is overlooked and all your questions are 
        addressed promptly. We take pride in our high quality customer service, and 
        it is our goal to ensure you have the best home buying experience possible.`;
      case 2:
        return `Now that you are pre-approved, you can begin your home search. You can talk with
         your realtor and find the home you want at the price that works for you. Once the home you want has been identified, you will be ready to make a formal offer to purchase.`;
      case 3:
        return `After the sellers have formally accepted your offer, the home is now in 'Escrow'.
         Once escrow is opened, we will work with you to collect any additional documents the lender may 
         need to fund your loan. We will submit all the 
        paper work in a timely manner so that your escrow can close on time.`;
      case 4:
        return `After all conditions of the contract and loan have been met, your loan will be funded and the sale of the home recorded. Congratulations! You are now a home owner and part of the Redding Home Loans family.`;
      default:
        return 'Unknown step';
    }
  }
  function GetStepGraphic(params) {
    const { step } = params;
    switch (step) {
      case 0:
        return <ApplyIcon className={classes.icons} />;
      case 1:
        return <ConsultIcon className={classes.icons} />;
      case 2:
        return <ShopIcon className={classes.icons} />;
      case 3:
        return <EscrowIcon className={classes.icons} />;
      case 4:
        return <HomeIcon className={classes.icons} />;
      default:
        return <div></div>;
    }
  }

  return (
    <Grid container justify='center' className={classes.background}>
      <Typography variant='h3' className={classes.header} classes={{ h3: classes.typeHeader }}>
        Our Home Loan Process
      </Typography>
      <Grid container justify='center'  >
        <Grid item className={classes.container}>
          <Stepper activeStep={activeStep} className={classes.root} orientation="vertical" connector={<Connector />}>
            {steps.map((label, index) => (
              <Step key={label} completed={null}>
                <StepLabel classes={{ label: classes.label, active: classes.activeLabel }} onClick={handleStep(index)} StepIconComponent={StepIcon}>{label}</StepLabel>
                <StepContent className={classes.stepContainer}>
                  <Typography className={classes.stepContent}>{getStepContent(index)}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid container justify='center' alignItems='center' className={classes.carousel}>
          <div >
            <GetStepGraphic step={activeStep} />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
