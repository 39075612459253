import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import TextsmsIcon from '@material-ui/icons/Textsms';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import EmailIcon from '@material-ui/icons/Email';

import Colors from './colors';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    navBar: {
        position: 'fixed',
        bottom: theme.spacing(0),
        right: theme.spacing(0),
        left: theme.spacing(0),
        color: Colors.dark,
        background: Colors.Accent2

    },
    SpeedDialAction: {
        color: Colors.white,
        background: Colors.Accent2,
        border: '1px solid #fff',
    },
    tooltip: {
        color: Colors.dark
    },
    fab: {

        border: '2px solid #fff',
    }
}));

const actions = [
    { icon: <PhoneInTalkIcon />, name: 'Call', href: 'tel:5309410230'},
    { icon: <TextsmsIcon />, name: 'Text', href: 'sms:5309410230'},
    { icon: <EmailIcon />, name: 'Email', href: 'mailto:josh@reddinghomeloans.com'},
];

export default function OpenIconSpeedDial() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <BottomNavigation
                // classes={{fab: classes.fab}}
                className={classes.navBar}
                icon={<ContactSupportIcon  />}
                onClose={handleClose}
                onChange={handleOpen}
                open={open}
            >
                {actions.map(action => (
                    <BottomNavigationAction
                        // classes={{staticTooltipLabel: classes.tooltip, fab: classes.SpeedDialAction}}
                        key={action.name}
                        icon={action.icon}
                        label={action.name}
                        href={action.href}
                        onClick={handleClose}
                        color='primary'
                    />
                ))}
            </BottomNavigation>
        </div>
    );
}

