import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../components/colors';
import { useStore } from '../components/useFormState';

const useStyles = makeStyles(theme => ({


    root: {
        margin: '3rem',
        width: '100%'
    },
    field: {
        margin: '0 0 1rem'
    },
    inputRoot: {
        color: Colors.white,
        background: Colors.Accent2,
        fontWeight: 'bold',
        boxShadow: Colors.shadow,
        "&focus": {
            color: Colors.darkAccent1
        }
    },
    inputRootFocused: {
        color: Colors.Accent2,

    },

}));


export default function FormFinance() {

    const classes = useStyles();

    const { state, dispatch } = useStore();
    const pickLists = (options) => {
        return options.map(option => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))
    }
    const Employments = [{ value: 'Employed', label: 'Employed' }, { value: 'Retired', label: 'Retired' }, { value: 'Unemployed', label: 'Unemployed' }];

    const Terms = [{ value: '+10 years', label: 'More than 10 Years' }, { value: '5-10 years', label: 'Between 5 and 10 Years' }, { value: '1-5 years', label: 'Between 1 and 5 Years' }, { value: '0-1 year', label: 'Less than 1 year' }];

    const Scores = [{ value: 'Excellent', label: 'Excellent >740' }, { value: 'Good', label: 'Good >700' }, { value: 'Fair', label: 'Fair >640' }, { value: 'Poor', label: 'Poor <640' }];



    const finFields = [
        { id: "applicant-employed", label: "Employment Status", placeholder: "Enter Employer", name: 'employmentStatus', state: state.employmentStatus, type: 'text', select: true, options: pickLists(Employments) },
        { id: "applicant-employment-term", label: "Time with current employer", placeholder: "Select Range", name: 'employmentTerm', state: state.employmentTerm, type: 'text', select: true, options: pickLists(Terms), disabled: state.employmentStatus.value === 'Retired' ? true : false },
        { id: "applicant-credit-score", label: "Credit Score", placeholder: "Excellent >740 ", name: 'creditScore', state: state.creditScore, type: 'text', select: true, options: pickLists(Scores) },
    ];




    return (


        <form className={classes.root} noValidate  >
            {finFields.map((field, index) => (
                <TextField
                    variant="filled"
                    color="primary"
                    fullWidth={true}
                    className={classes.field}
                    name={field.name}
                    id={field.id}
                    key={index}
                    type={field.type}
                    label={field.label}
                    select={field.select}
                    disabled={field.disabled}
                    required={field.state.required}
                    value={field.state.value}
                    error={field.state.error}
                    helperText={field.state.errorMsg}
                    placeholder={field.placeholder}
                    InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot, focused: classes.inputRootFocused } }}
                    onChange={(e) => { dispatch({ type: "handleChange", name: e.target.name, value: e.target.value }) }}
                    onBlur={(e) => { dispatch({ type: "handleBlur", name: e.target.name, value: e.target.value }) }}
                >
                    {field.options}
                </TextField>


            ))}
        </form>

    )
}