import React from 'react'
import Colors from '../components/colors'

const SvgComponent = props => (
    <svg viewBox="0 0 123.8 114.28" {...props}>
        <defs>
            <style>{`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}</style>
        </defs>
        <title>{'Asset 5'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g id="prefix__Layer_1-2" data-name="Layer 1">
                <path
                    className="prefix__cls-1"
                    d="M78.12 17.19c-.05-2.33 0-4.65 0-7V8.88H45.7v8.33z"
                />
                <path
                    className="prefix__cls-1"
                    d="M111.93 9a56.61 56.61 0 00-7.14-.27h-9.43v-4a4.64 4.64 0 00-6-4.61A4.17 4.17 0 0085.92 4c-.07 2.3 0 4.61 0 6.91s-.07 4.7 0 7a4.34 4.34 0 004 3.89A4.67 4.67 0 0095 19a6.25 6.25 0 00.3-1.76h13.43c3.6 0 5.56 1.8 5.56 5.11v8.5H9.48V22.3c0-3.31 2-5.11 5.56-5.11h13.43a6.26 6.26 0 00.31 1.81 4.66 4.66 0 005.13 2.87 4.34 4.34 0 004-3.89c.09-2.35 0-4.7 0-7S38 6.27 37.88 4A4.15 4.15 0 0034.5.19a4.64 4.64 0 00-6 4.61v4h-9.43a56.68 56.68 0 00-7.2.2C4.71 10.17 0 15.56 0 22.41v78.25c0 7.87 6.24 13.61 14.78 13.61h94.25c8.53 0 14.75-5.74 14.77-13.61V22.41c0-6.85-4.7-12.24-11.87-13.41zm2.39 91.4c0 3.3-2 5.13-5.56 5.13H15c-3.6 0-5.56-1.83-5.56-5.13V37.93h104.88z"
                />
                <path
                    className="prefix__cls-1"
                    d="M94.87 83.82H105v-4.38h-9.21a11.56 11.56 0 01-.92 4.38zM83.55 59.14V42.6h-4.77v16.54H56.65a11 11 0 01.1 1.53 11.71 11.71 0 01-.35 2.85h22.38v3.41a14.94 14.94 0 013.22-.36c.52 0 1 0 1.54.08v-3.13H105v-4.38zm-4.77 32.43v8.79h4.77v-8.51c-.51.05-1 .08-1.54.08a14.34 14.34 0 01-3.23-.36zM45 79.44v-6.23a14.86 14.86 0 01-2 .13 14.65 14.65 0 01-2.75-.25v6.35H18.79v4.38h21.43v16.54H45V83.82h24.15a11.76 11.76 0 01-.92-4.38zM40.22 42.6v5.65A14.65 14.65 0 0143 48a14.86 14.86 0 012 .14V42.6zm-11 18.07a11 11 0 01.1-1.53H18.79v4.38h10.75a11.24 11.24 0 01-.35-2.85z"
                />
                <path
                    className="prefix__cls-2"
                    d="M43 49.86c-6.49 0-11.76 4.84-11.76 10.81S36.48 71.48 43 71.48s11.76-4.84 11.76-10.81S49.46 49.86 43 49.86zm8 8.24l-8.8 8.06a1.81 1.81 0 01-1.23.46 1.78 1.78 0 01-1.22-.46L35 61.77a1.91 1.91 0 01-.64-1.52 2 2 0 01.8-1.44 2.39 2.39 0 013 .2L41 61.64l6.86-6.3a2.39 2.39 0 013-.2 2 2 0 01.8 1.45A1.9 1.9 0 0151 58.1z"

                />
                <path
                    className="prefix__cls-2"
                    d="M71.81 87.38a4 4 0 005.09 0l4-3.39 4 3.39a4 4 0 005.1 0 2.78 2.78 0 000-4.35l-4-3.4 4-3.39a2.79 2.79 0 000-4.36 4.06 4.06 0 00-5.1 0l-4 3.39-4-3.39a4 4 0 00-5.09 0 2.78 2.78 0 000 4.36l4 3.39-4 3.4a2.77 2.77 0 000 4.35z"

                />
            </g>
        </g>

    </svg>
)

export default SvgComponent