import React from 'react';
import Colors from '../components/colors';
const SvgComponent = props => (
    <svg viewBox="0 0 63.75 60.97" {...props}>
        <defs>
            <style>
                {`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}
            </style>
        </defs>
        <title>{'Asset 9'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g
                id="prefix__Real_Estate_Homescapes_Icons"
                data-name="Real Estate &amp; Homescapes Icons"
            >
                <g id="prefix__House_Measurements" data-name="House Measurements">
                    <path
                        className="prefix__cls-2"
                        d="M63.44 4.31L58.22.19a.87.87 0 00-.51-.19.83.83 0 00-.83.82v2.34a.19.19 0 01-.2.19H17.89a.24.24 0 01-.14-.05.24.24 0 01-.05-.14V.82a.84.84 0 00-.83-.82.79.79 0 00-.51.18c-1.36 1.08-4.1 3.24-5.22 4.13a.82.82 0 00-.31.65.83.83 0 00.31.65l6.06 4.77.53.41v-.67a1 1 0 000-.16V6.72a.16.16 0 01.06-.13.2.2 0 01.14-.06h38.75a.21.21 0 01.2.2v2.34a.83.83 0 00.83.83.81.81 0 00.51-.18l5.22-4.12a.8.8 0 00.31-.6.82.82 0 00-.31-.69zM9.25 54.55h-3a.16.16 0 01-.16-.15V18.49a.16.16 0 01.16-.16h2.17a.8.8 0 00.71-.45.78.78 0 00-.13-.82c-1.18-1.51-3-3.84-3.81-4.83a.79.79 0 00-1.24 0L.18 17.06a.78.78 0 00-.08.83.8.8 0 00.7.45H3a.16.16 0 01.16.15V54.4a.16.16 0 01-.16.16H.79a.77.77 0 00-.71.44.78.78 0 00.09.83L4 60.67a.81.81 0 00.62.3.78.78 0 00.62-.3c1.16-1.46 3.57-4.52 4.42-5.61l.41-.53h-.66z"
                    />
                    <path
                        className="prefix__cls-1"
                        d="M20.18 15.77h4.25a1.13 1.13 0 011.12 1.13v2.6c.51-.35.79-.53 1.05-.72l8.31-6.4a3.13 3.13 0 014.11.15L51 21.7q4.83 3.72 9.68 7.44a2.45 2.45 0 011.2 2.48c-.4 2-3 2.74-4.84 1.34-2.77-2.08-5.5-4.21-8.24-6.32-3.62-2.78-7.26-5.54-10.84-8.36a1.37 1.37 0 00-2.06 0c-6.31 4.88-12.67 9.73-19 14.57a4.74 4.74 0 01-1.51.83 3 3 0 01-3.24-1.08 2.3 2.3 0 01.6-3.15c1.85-1.46 3.77-2.85 5.59-4.33a2.08 2.08 0 00.73-1.4c.07-2.14.05-4.28 0-6.81a1.13 1.13 0 011.11-1.14z"
                    />
                    <path
                        className="prefix__cls-2"
                        d="M42.6 46.16h-4.3v-3.8c0-.19.43-.51.67-.52 1.18-.05 2.37 0 3.6 0zM44.8 41.82h3.61a.94.94 0 01.68.64c.07 1.2 0 2.41 0 3.66H44.8zM38.28 48.33h4.31v4.33H38.9c-.22 0-.58-.46-.6-.73-.06-1.19-.02-2.36-.02-3.6zM49.13 48.35v3.6a1 1 0 01-.65.68c-1.2.07-2.41 0-3.65 0v-4.28z"
                    />
                    <path
                        className="prefix__cls-1"
                        d="M58.34 57.57a1.94 1.94 0 000-.24V37.17l-1.71-1.24L36.87 21.5l-.07.05-.06-.05L17 35.92l-1.71 1.24v20.17a1.94 1.94 0 000 .24h-1.83v3.13h46.69v-3.13zm-25.95 0c.09-.06.12-.17.12-.36v-6-5.49a6.51 6.51 0 00-.08-1.07 4.85 4.85 0 00-5.87-3.86 5.07 5.07 0 00-3.81 5.05v11.35c0 .21 0 .33.05.38h-3.95a1.94 1.94 0 000-.24V39.92a1.81 1.81 0 01.75-1.46l.45-.32 15.69-11.36a1.8 1.8 0 012.12 0l15.68 11.36.45.32a1.8 1.8 0 01.74 1.46v17.41a1.94 1.94 0 000 .24z"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default SvgComponent
