
import home from '../images/heroBG.jpg';
import purchase from '../images/purchaseHeroBG.jpg'
import refinance from '../images/refiBG.jpg'
import rates from '../images/ratesHeroBG.jpg'

const seo = {
    "": {
        title: "Better Rates | Redding Home Loans",
        description:
            "The lender with better rates for Redding, CA. and Northern California. We have the rates you need and the service you expect.",
        url: "https://www.reddinghomeloans.com/",
        image: home
    },
    purchase: {
        title: "Purchase Loans | Redding Home Loans",
        description:
            "We have great programs for purchase loans, be it VA Loans, USDA Loans, FHA Loans, or Conventional Mortgages we have great rates.",
        url: "https://www.reddinghomeloans.com/purchase",
        image: purchase
    },
    refinance: {
        title: "Refinance Loans | Redding Home Loans",
        description:
            "Our refinance programs offer VA Loans, USDA Loans, FHA Loans or Conventional Mortgages at outstanding rates.",
        url: "https://www.reddinghomeloans.com/refinance",
        image: refinance
    },
    rates: {
        title: "Current Rates | Redding Home Loans",
        description:
            "Updated on Friday each week, our rates are some of the lowest in the Redding, CA and Northern California. People love us for our rates but comeback for the unmatched service.",
        url: "https://www.reddinghomeloans.com/rates",
        image: rates
    },
    apply: {
        title: "Pre Approval Application | Redding Home Loans",
        description:
            "We stand behind our servie get pre approved today and see why our clients return again and again.",
        url: "https://www.reddinghomeloans.com/apply",
        image: home
    },
    privacy: {
        title: "Privacy Policy | Redding Home Loans",
        description:
            "We care about your privacy and work to keep it secure.",
        url: "https://www.reddinghomeloans.com/privacy",
        image: home
    }
}
export default seo

