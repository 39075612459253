import React from 'react'
import Colors from '../components/colors'
const SvgComponent = props => (
    <svg viewBox="0 0 120.86 100.44" {...props}>
        <defs>
            <style> {`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}</style>
        </defs>
        <title>{'Asset 2'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g id="prefix__Layer_1-2" data-name="Layer 1">
                <path
                    className="prefix__cls-1"
                    d="M105.45 31.29L64.62 25 23.9 18a11.61 11.61 0 00-13.17 9.33l-7.4 45.5a11.62 11.62 0 009.53 13l5.51.85a12.85 12.85 0 012.06-7l-6.39-1A3.6 3.6 0 0110.55 74l3.34-22.78 4.05-22.66a3.61 3.61 0 014.79-3.31l40.76 6.63 39.64 6.45a11.62 11.62 0 016-1.55 12.05 12.05 0 014.78.93 11.54 11.54 0 00-8.46-6.42z"
                />
                <path
                    className="prefix__cls-1"
                    d="M109.36 31.71l-41.31.36-41.31-.36A11.62 11.62 0 0015.24 43v46.11a11.62 11.62 0 0011.5 11.33l41.31-.37 41.31.37a11.62 11.62 0 0011.5-11.33V43a11.62 11.62 0 00-11.5-11.29zm0 61.41H26.74a3.59 3.59 0 01-4.19-4l-.36-23 .36-23a3.6 3.6 0 014.19-4h82.62a3.6 3.6 0 014.19 4l.36 23-.36 23a3.59 3.59 0 01-4.19 4z"
                />
                <path
                    className="prefix__cls-2"
                    d="M94.14 44.5H42s-6.25 11.06-12.87 12.91v17.33C35.75 76.58 42 87.64 42 87.64h52.14s6.21-11.06 12.83-12.9V57.41c-6.62-1.85-12.83-12.91-12.83-12.91zM70 77.69a9.12 9.12 0 01-3.8 0 12.52 12.52 0 01-10.33-11.62 12.54 12.54 0 0110.28-11.62 9.59 9.59 0 013.8 0 12.54 12.54 0 0110.28 11.62A12.52 12.52 0 0170 77.69z"

                />
                <path
                    className="prefix__cls-1"
                    d="M8.05 57.21a3.64 3.64 0 01-.57-4.14l7.65-21.72 8.33-21.47a3.6 3.6 0 015.32-2.33l38.74 14.33 34.28 12.68c5-1.85 11.18-.63 14.3 3.68a11.56 11.56 0 00-7.31-8.89l-38.87-14L31.32.7a11.6 11.6 0 00-14.71 6.63L.62 50.54a11.68 11.68 0 003.9 13 11.63 11.63 0 013.53-6.33z"
                />
            </g>
        </g>
    </svg>
)

export default SvgComponent
