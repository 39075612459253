import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import bg from '../images/refiBG.jpg';
import bg2 from '../images/refiBG@2x.jpg';
import bg3 from '../images/refiBG@3x.jpg';
import Programs from '../components/programs';
import Colors from '../components/colors';
import Timeline from '../components/refiStatus';
import { Link } from 'react-router-dom';
import OtherPrograms from '../components/otherPrograms';
import { useStore } from '../components/useFormState';

const useStyles = makeStyles(theme => ({
    background: { background: Colors.owbg },
    hero: {
        margintop: '',
        height: '80vh',
        width: '100%',
        position: 'relative',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'top right',
            backgroundSize: 'cover',

        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${bg2})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',

        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${bg3})`,
            backgroundPosition: 'center 0px center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',

        },
    },
    accent: {
        color: Colors.darkAccent1
    },
    hero__cta__container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: Colors.dark,
        padding: '1.25rem',
        boxShadow: '0 10px 30px rgba(51,51,51,.9)',
        [theme.breakpoints.down('sm')]: {
            margin: '50vh 2vh 0',
            background: Colors.white,
            maxWidth: '90vw',
            borderRadius: '15px',
        },
        [theme.breakpoints.up('md')]: {
            margin: '25vh 0 0 10vw',
            background: Colors.white,
            maxWidth: '35vw',
            borderRadius: '5px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '25vh 0 0 10vw',
            background: Colors.white,
            maxWidth: '30vw',
            borderRadius: '5px',
        },
        [theme.breakpoints.up('xl')]: {
            margin: '25vh 0 0 20vw',
            background: Colors.white,
            maxWidth: '20vw',
            borderRadius: '5px',
        },
    },
    hero__cta__h2: {
        margin: '1rem 1rem 0',
        fontWeight: '900',

    },
    hero__cta__tag: {
        fontWeight: '500',
        fontSize: '1.25rem',
        margin: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
        hero__cta__buttons: {
            margin: '1rem 1vw',
            fontSize: '1rem',
            padding: '1rem 4rem',
            background: Colors.Accent2, //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))',
            color: Colors.white,
            textTransform: 'none',
            border: `solid ${Colors.Accent2}`,
            "&:hover": {
                color: Colors.Accent2,
                background: Colors.white,
                border: `solid ${Colors.Accent2}`
            }

        },
    },

    [theme.breakpoints.down('md')]: {
        hero__cta__buttons: {
            margin: '1rem 1vw',
            fontSize: '1rem',
            padding: '1rem 2rem',
            background: Colors.Accent2, //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))',
            color: Colors.white,
            textTransform: 'none',
            border: `solid ${Colors.Accent2}`,
            "&:hover": {
                color: Colors.Accent2,
                background: Colors.white,
                border: `solid ${Colors.Accent2}`
            }
        },
    },
    journey__h2: {
        marginBottom: '0',
        fontWeight: '900',
        "&::before": {
            color: Colors.white,
            fontSize: '1.5rem',
            display: '',
            margin: '0 .2rem 0',
            content: '""',
            padding: '0px .2rem',
            background: Colors.dark, //'linear-gradient(rgba(255, 0, 0, 0.9),rgb(167, 0, 0))', linear-gradient(rgb(139, 143, 109),rgb(62, 73, 33))
        },
        "&::after": {
            color: Colors.white,
            fontSize: '1.5rem',
            display: '',
            margin: '0 .2rem 0',
            content: '""',
            padding: '0px .2rem',
            background: Colors.dark, // 'linear-gradient(rgb(167, 0, 0), rgba(255, 0, 0, 0.9))', linear-gradient(rgb(62, 73, 33), rgb(139, 143, 109)
        }
    },
}));




export default function Home() {

    const classes = useStyles();
    const { dispatch } = useStore();
    return (
        <Grid container className={classes.background}>
            <Grid item sm={12} className={classes.hero}>
                <Grid item md={6} >
                    <div className={classes.hero__cta__container}>
                        <h1 className={classes.hero__cta__h2}>Refinance your <span className={classes.accent}>Home</span></h1>
                        <p className={classes.hero__cta__tag}>Whatever your reason, we have great options for refinancing your home.</p>
                        <div >
                            <Button
                                className={classes.hero__cta__buttons}
                                component={Link}
                                to='/apply'

                                onClick={(e) => { dispatch({ type: "handleChange", name: 'loanType', value: 'Refinance' }) }}
                            >
                                Get Started
                        </Button>

                        </div>
                    </div>
                </Grid>
            </Grid>
            <Timeline />
            <Programs />
            <OtherPrograms />

        </Grid>
    );

}
