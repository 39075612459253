import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../components/colors';
import { useStore } from '../components/useFormState'

const useStyles = makeStyles(theme => ({


    root: {
        margin: '3rem',
        width: '100%'
    },
    field: {
        margin: '0 0 1rem'
    },
    white: { fill: 'white' },
    test: {
        color: 'red',

    },
    inputRoot: {
        color: Colors.white,
        background: Colors.Accent2,
        fontWeight: 'bold',
        boxShadow: Colors.shadow,
        "&focus": {
            color: Colors.darkAccent1
        }
    },
    inputRootFocused: {
        color: Colors.Accent2,

    },
}));


export default function FormResidence() {

    const classes = useStyles();
    const { state, dispatch } = useStore();

    const pickLists = (options) => {
        return options.map(option => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))
    }

    const Residences = [{ value: 'Primary Residence', label: 'Primary Residence' }, { value: 'Investment Property', label: 'Investment Property' }, { value: 'Second Home', label: 'Second Home' }];

    const Terms = [{ value: '+10 years', label: '10 Years or more' }, { value: '5-10 years', label: 'Between 5 and 10 Years' }, { value: '1-5 years', label: 'Between 1 and 5 Years' }, { value: '0-1 year', label: 'Less than 1 year' }];

    const owner = [{ value: 'Yes, paid off.', label: 'Yes, paid off.' }, { value: 'Yes, with an existing mortgage.', label: 'Yes, with an existing mortgage.' }, { value: 'No, currently renting.', label: 'No, currently renting.' }, { value: 'No, other.', label: 'No, other.' }];

    const homeFields = [
        { id: "applicant-residence-type", label: "Mortgage purpose?", placeholder: "", name: 'residenceType', state: state.residenceType, type: 'text', select: true, options: pickLists(Residences) },

        { id: "applicant-home-owner", label: "Home owner currently?", placeholder: "", name: 'homeOwner', state: state.homeOwner, type: 'text', select: true, options: pickLists(owner) },
        { id: "applicant-resicence-term", label: "How long have you lived there?", placeholder: "Hage", name: 'residenceTerm', state: state.residenceTerm, type: 'text', select: true, options: pickLists(Terms) },
    ];




    return (


        <form className={classes.root} noValidate  >
            {homeFields.map((field, index) => (
                <TextField
                    variant="filled"
                    color="primary"
                    fullWidth={true}
                    className={classes.field}
                    name={field.name}
                    id={field.id}
                    key={index}
                    type={field.type}
                    label={field.label}
                    select={field.select}
                    required={field.state.required}
                    value={field.state.value}
                    error={field.state.error}
                    helperText={field.state.errorMsg}
                    placeholder={field.placeholder}
                    InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot, focused: classes.inputRootFocused } }}
                    onChange={(e) => { dispatch({ type: "handleChange", name: e.target.name, value: e.target.value }) }}
                    onBlur={(e) => { dispatch({ type: "handleBlur", name: e.target.name, value: e.target.value }) }}
                >
                    {field.options}
                </TextField>


            ))}
        </form>

    )
}