import React from 'react';
import ErrorImg from '../images/404';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
    }
}));

export default function NoMatchPage() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <ErrorImg style={{ height: '15vh' }} />
            <h3>404 - Not found</h3>
        </div>
    );
};