import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HouseIcon from '@material-ui/icons/House';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Colors from './colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    padding: '0 0 3rem',
    backgroundColor: Colors.owbg,
  },
  header: {
    margin: '2rem 1rem ',
    fontWeight: '900',
    fontSize: '2.25rem'
  },
  icons: {
    color: Colors.Accent2,
    fontSize: '3.5rem'
  },
  icon: {
    height: '7em',
    width: '6em',
    color: Colors.white
  },
  iconWrapper: {
    background: Colors.white,
    border: `5px solid ${Colors.lightAccent1}`,
    borderRadius: '50%',
    padding: '.6rem',
    height: '80px',
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '3px 7px 9px 2px rgba(0, 0, 0, 0.4), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  dropdown: {
    background: Colors.Accent2,
    minHeight: '100px',
    borderRadius: '0 0px 15px 15px',
    boxShadow: '3px 7px 9px 2px rgba(0, 0, 0, 0.4), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.up('md')]: {
      width: '60%'
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%'
    }
  },
  innerDropdown: {
    color: Colors.dark,
    fontSize: '1.25rem',
    padding: '1rem',
    borderRadius: '5px',
    background: Colors.white,
  },
  tabRoot: {
    fontSize: '1.5rem',
    minWidth: 'fit-content'
  },
  selected: {
    fontWeight: 'bold !important',
  },
  textColorInherit: {
    fontWeight: '100'
  },
  scrollButtons: {
    transform: 'scale(2.5)',
    width: '60px'
  }
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const mediumOrBelow = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

      <Typography
        style={{ display: 'flex', justifyContent: 'center', background: Colors.owbg, }}
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && <Box className={classes.dropdown} p={3}><div className={classes.innerDropdown}>{children}</div></Box>}
      </Typography>

    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary">
        <Typography
          variant="h2"
          align='center'
          className={classes.header}
          gutterBottom
        >
          Traditional Mortgage Programs</Typography>
        <Tabs
          centered={!!mediumOrBelow ? false : true}
          classes={{ scrollButtons: classes.scrollButtons }}
          variant={!!mediumOrBelow ? 'scrollable' : 'standard'}
          scrollButtons='on'
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          aria-label="scrollable force tabs example"
          TabIndicatorProps={{ style: { background: 'white', height: '4px' } }}
        >
          <Tab classes={{ labelIcon: classes.icon, root: classes.tabRoot, selected: classes.selected, textColorInherit: classes.textColorInherit }} label="Conventional" icon={<div className={classes.iconWrapper}><AccountBalanceIcon className={classes.icons} /></div>} {...a11yProps(0)} />
          <Tab classes={{ labelIcon: classes.icon, root: classes.tabRoot, selected: classes.selected, textColorInherit: classes.textColorInherit }} label="USDA Loans" icon={<div className={classes.iconWrapper}><HomeWorkIcon className={classes.icons} /></div>} {...a11yProps(1)} />
          <Tab classes={{ labelIcon: classes.icon, root: classes.tabRoot, selected: classes.selected, textColorInherit: classes.textColorInherit }} label="FHA Loans" icon={<div className={classes.iconWrapper}><HouseIcon className={classes.icons} /></div>} {...a11yProps(2)} />
          <Tab classes={{ labelIcon: classes.icon, root: classes.tabRoot, selected: classes.selected, textColorInherit: classes.textColorInherit }} label="VA Loans" icon={<div className={classes.iconWrapper}><EmojiFlagsIcon className={classes.icons} /></div>} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        A Conventional loan is  great option for a home purchase or refinance in the Redding area. These loans offer varied down payment options, and typically the lowest payments for borrowers with good credit. Because of the combined flexibility and savings offered, these conventional loan programs are our most popular option for our Shasta County borrowers.
      </TabPanel>
      <TabPanel value={value} index={1}>
        USDA loans are a fantastic way for people to purchase a home with no money down. Because these loans are subsidized by the US Department of Agriculture (yes, the same people that inspect your meat and produce), these loans are very affordable and offer very low interest rates. If you have little or no down payment, or want to save your down payment for something else, this is a great loan program for you.
      </TabPanel>
      <TabPanel value={value} index={2}>
        At Redding Home Loans, we think FHA loans are great! Why are they so great? For starters, FHA loans only require a down payment of 3.5% of the home's purchase price. This down payment can even be a gift. Add to that FHA will approve loans and offer the most competitive rates for borrowers with less than perfect credit, even with scores as low as 580. An FHA loan could be your best option for your next home purchase
      </TabPanel>
      <TabPanel value={value} index={3}>
        A VA Loan is quite possibly the best loan product on the market today. As a VA Loan specialist, I love to help the veterans in our community with the home loan process. A VA home loan offers veterans an opportunity to buy a home with no money down. For veterans, the rates on a VA Loan are lower than any other loan program offered on the market today. You have served; We would be honored to serve you and help with your VA Loan.
      </TabPanel>

    </div>
  );
}
