import React from 'react'
import Colors from '../components/colors'
const SvgComponent = props => (
    <svg viewBox="0 0 63.27 44.01" {...props}>
        <defs>
            <style>
                {`.prefix__cls-1{fill:${Colors.dark}}.prefix__cls-2{fill:${Colors.Accent2}}`}
            </style>
        </defs>
        <title>{'Asset 7'}</title>
        <g id="prefix__Layer_2" data-name="Layer 2">
            <g
                id="prefix__Real_Estate_Homescapes_Icons"
                data-name="Real Estate &amp; Homescapes Icons"
            >
                <path
                    className="prefix__cls-1"
                    d="M63 15.27a1.86 1.86 0 01-1.51.74 1.88 1.88 0 01-.53-.07 3.32 3.32 0 01-1-.56c-4.71-3.6-9.58-7.31-14.41-11.06a1.7 1.7 0 00-1-.42 1.67 1.67 0 00-1 .44c-2 1.58-4.09 3.17-6.09 4.7l-2.13 1.64-1.83 1.39c-1.45 1.12-2.94 2.28-4.43 3.39a2.31 2.31 0 01-1.38.48A1.66 1.66 0 0126 14.67c-.11-.55.13-1 .78-1.51l4.8-3.68c.84-.66 1.69-1.31 2.54-2l1-.8 8-6.15a2 2 0 012.7-.08l6.31 4.85a4.86 4.86 0 00.45.32l.35.24.59.4V3.43h4.18v5.33a2 2 0 00.69 1.34c.82.66 1.67 1.31 2.49 1.93.58.45 1.18.9 1.76 1.36a1.36 1.36 0 01.36 1.88z"
                />
                <path
                    className="prefix__cls-2"
                    d="M45.82 17.25h4.01v3.5h-4.01zM39.25 17.25h4.01v3.5h-4.01zM45.82 23.19h4.01v3.5h-4.01zM39.25 23.19h4.01v3.5h-4.01z"
                />
                <path
                    className="prefix__cls-1"
                    d="M60.66 18.62l-6.5-4.72-9.57-7h-.05L29.8 17.63l-1 .68H4.59L11.91 11h13.78a1.37 1.37 0 001.38-1.25 1.34 1.34 0 00-1.34-1.45H10.73a.28.28 0 00-.1.09l-10 10-.09.1v.16a.09.09 0 000 .05.11.11 0 000 .05v.08a1.13 1.13 0 000 .26v.08a.07.07 0 000 .05A.13.13 0 000 20v.1a.17.17 0 000 .05v.05a1.14 1.14 0 00.19.19h30.05a1.13 1.13 0 00.73-.36c.15-.13 12.79-9.8 12.79-9.8a1.34 1.34 0 011.57 0L57 19.28l.23.17 1.81 1.32a1.31 1.31 0 00.79.26 1.35 1.35 0 00.8-2.43zM18.2 34.88h-4a6.46 6.46 0 01.91 2.54h2.17a6.47 6.47 0 01.92-2.54zM60.56 34.88V23.61a1.27 1.27 0 00-2.54 0v11.27H31.51V23.61a1.27 1.27 0 00-2.54 0v11.27h-.77a6.46 6.46 0 01.91 2.54h30.18a1.27 1.27 0 001.27-1.27zM4.2 34.88H2.61V23.61a1.27 1.27 0 00-2.54 0v12.54a1.27 1.27 0 001.27 1.27h1.94a6.47 6.47 0 01.92-2.54z"
                />
                <path
                    className="prefix__cls-2"
                    d="M9.37 32.85a5.35 5.35 0 00-.06 10.7 5.35 5.35 0 10.06-10.7zm0 7.8a2.38 2.38 0 01-2.4-2.48 2.41 2.41 0 012.47-2.42 2.44 2.44 0 012.43 2.49 2.41 2.41 0 01-2.55 2.41zM23.1 33.31a5.35 5.35 0 105.34 5.35 5.38 5.38 0 00-5.34-5.35zm-.05 7.8a2.45 2.45 0 01.06-4.9 2.45 2.45 0 11-.06 4.9z"
                />
            </g>
        </g>
    </svg>
)

export default SvgComponent
